import React, { useMemo } from 'react';
import styles from './expired-knowledge-widget.module.scss';
import { KnowledgeWrapperComponent } from '../../../knowledge/components/knowledge-row';
import { SectionComponent } from 'src/components/common/section/section.component';
import { formatFullName } from 'shared/utils';
import { colorPalette } from 'shared/constants';
import { Divider, notification, Progress } from 'antd';
import classNames from 'classnames';
import { PandaThumbsUp } from 'shared/images/characters';
import _ from 'lodash';
import ownersServiceApi from 'shared/api/owners.service.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

const ExpiredKnowledgeWidgetComponent = React.memo(function ExpiredKnowledgeWidgetComponent({
  title = '',
  items = {},
  allowEditExpired = false,
  userId,
  owners,
  knowledgeItemsCount,
  lang,
}) {
  const [emailSents, setEmailSents] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const itemsForReview = useMemo(
    () =>
      new Set(
        Object.values(items)
          .flat()
          .map(item => item.id),
      ).size,
    [items],
  );
  const percentage = useMemo(
    () =>
      itemsForReview === 0 ? 100 : Math.floor((1 - itemsForReview / knowledgeItemsCount) * 100),
    [itemsForReview, knowledgeItemsCount],
  );
  const { unassigned, ...restItems } = items;
  const [api, contextHolder] = notification.useNotification();

  if (_.isArray(items) && _.isEmpty(items)) {
    return null;
  }

  const getColorFromPercentage = percentage => {
    if (percentage <= 80) {
      return colorPalette.red;
    } else if (percentage <= 90) {
      return colorPalette.orange;
    } else if (percentage < 100) {
      return colorPalette.primaryMain5;
    } else {
      return colorPalette.greenPrimary;
    }
  };

  const renderTitle = (id, size) => {
    const owner = owners.find(o => o.id === id);
    const name = formatFullName(owner, {
      labelInParenthesis: owner?.isDeactivated ? lang.DEACTIVATED : null,
    });
    const emailWasSent = emailSents.includes(id);

    const onNotifyOwner = async e => {
      e.stopPropagation();
      if (emailWasSent) {
        return;
      }

      setLoading(loading => [...loading, id]);
      try {
        const res = await ownersServiceApi.notifyOwner({
          ownerId: id,
          items: items[id].map(({ id }) => id),
        });

        if (res.error) {
          showError();
          return;
        }
        setEmailSents(sent => [...sent, id]);
      } catch (error) {
        console.error('Error sending email', error);
        showError();
      } finally {
        setLoading(loading => loading.filter(i => i !== id));
      }
    };

    const showError = () =>
      api.error({
        message: lang.ERROR_OCCURRED,
        placement: 'bottomRight',
      });

    return (
      <div className={styles.sectionTitle}>
        <p>{`${name} (${size})`}</p>
        {id !== userId ? (
          loading.includes(id) ? (
            <FontAwesomeIcon className={styles.loadingIcon} icon={faSpinner} spin />
          ) : (
            <p
              className={classNames([styles.email, emailWasSent && styles.emailSent])}
              onClick={onNotifyOwner}
            >
              {emailWasSent ? lang.EMAIL_SENT : lang.EMAIL_REMINDER}
            </p>
          )
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.content}>
      {contextHolder}
      <label className={styles.title}>{title}</label>
      <div className={styles.widgetItems}>
        <div className={styles.header}>
          <h3 className={styles.count}>
            {itemsForReview > 0
              ? lang.ITEMS_UP_FOR_REVIEW?.replace(
                  '{count}',
                  new Intl.NumberFormat('en-US').format(itemsForReview),
                )
              : lang.ITEMS_UP_TO_DATE}
          </h3>
          <div className={styles.progress}>
            <Progress
              percent={percentage}
              showInfo={false}
              strokeColor={getColorFromPercentage(percentage)}
              className={styles.progressBar}
            />
            <p>
              <strong>{percentage}%</strong>
              {lang.PERCENTAGE_LABEL}
            </p>
          </div>
        </div>
        <Divider className={styles.titleDivider} />
        {Object.values(items).length > 0 ? (
          <>
            {Object.entries(restItems).map(([ownerId, itemsForReview = []], i) => (
              <SectionComponent
                title={renderTitle(ownerId, itemsForReview.length)}
                isOpen={false}
                backgroundColor={colorPalette.white}
                key={i}
              >
                {itemsForReview?.map(item => (
                  <KnowledgeWrapperComponent
                    key={item.id}
                    item={item}
                    draggable={false}
                    showSpecialty
                    allowEditExpired={allowEditExpired}
                  />
                ))}
              </SectionComponent>
            ))}
            {unassigned && (
              <SectionComponent
                title={`Unassigned (${unassigned.length})`}
                isOpen={false}
                backgroundColor={colorPalette.white}
                key="unassigned"
              >
                {unassigned?.map(item => (
                  <KnowledgeWrapperComponent
                    key={item.id}
                    item={item}
                    draggable={false}
                    showSpecialty
                    allowEditExpired={allowEditExpired}
                  />
                ))}
              </SectionComponent>
            )}
          </>
        ) : (
          <div className={styles.noContent}>
            <img src={PandaThumbsUp} alt="panda thumbs up" />
            <p>{lang.NO_CONTENT}</p>
          </div>
        )}
      </div>
    </div>
  );
});

export { ExpiredKnowledgeWidgetComponent };
