import { connect } from 'react-redux';
import { LabsPageComponent } from './Labs.page.component';
import {
  getLabRoot,
  getLabCategoryById,
  getLabCategoriesById,
} from 'shared/store/selectors/lab.selector';
import { getSelectedWorkspace } from 'shared/store/selectors/workspace.selector';

const mapStateToProps = ({ lab, workspace }, { match }) => {
  let category;
  let { id } = match.params;
  let isRoot = false;

  if (id) {
    category = id ? getLabCategoryById(lab, { id }) : {};
  } else {
    category = getLabRoot(lab);
    id = category.id;
    isRoot = true;
  }

  const workspaceName = getSelectedWorkspace({ workspace })?.name;

  return {
    id,
    category: {
      ...category,
      title: isRoot ? workspaceName : category.title,
    },
    showBreadcrumbs: !isRoot,
    children: getLabCategoriesById(lab, id) || [],
    lastUpdated: lab.lastUpdated,
    isReady: lab.isReady,
  };
};

export const LabsPage = connect(mapStateToProps, {})(LabsPageComponent);
