import styles from './text-field.module.scss';
import { useState } from 'react';
import { useFormik } from 'formik';
import { TextInput } from '../text-input.component';
import { Button } from '../../buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

/**
 * Text field component
 * @param {object} textFieldProps
 * @param {string} textFieldProps.name the name of the field
 * @param {string} textFieldProps.label the label of the field
 * @param {string} textFieldProps.initialValue the initial value of the field
 * @param {import('yup').ObjectSchema<{[x: string]: string}>} textFieldProps.schema the schema to validate the field
 * @param {boolean} [textFieldProps.canEdit=false] whether the field can be edited
 * @param {(value: string) => Promise<void>} textFieldProps.onChange function to apply changes
 * @param {boolean} [textFieldProps.initialEditing=false] whether the field is initially in editing mode
 * @param {boolean} [textFieldProps.withLoading=false] whether to show loading spinner
 * @param {object} textFieldProps.lang the language object
 * @param {import('antd').InputProps} textFieldProps.props additional props to pass to the TextInput component
 */
export function TextField({
  name,
  label,
  initialValue,
  schema,
  canEdit = false,
  applyChanges = () => {},
  initialEditing = false,
  withLoading = false,
  lang,
  ...props
}) {
  const [editing, setEditing] = useState(initialEditing && canEdit);
  const [loading, setLoading] = useState(false);

  const onApplyChanges = async (values, { resetForm }) => {
    setEditing(false);
    setLoading(true);
    await applyChanges(values[name]);
    setLoading(false);
    resetForm();
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty } = useFormik({
    initialValues: {
      [name]: initialValue,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: onApplyChanges,
  });

  const onCancel = () => {
    setEditing(false);

    handleChange({ target: { name, value: initialValue } });
  };

  return editing ? (
    <form onSubmit={handleSubmit} className={styles.editing}>
      <label className={styles.label}>{label}</label>
      <TextInput
        onBlur={handleBlur}
        name={name}
        error={errors[name]}
        value={values[name]}
        onChange={handleChange}
        {...props}
      />
      <div className={styles.submitButtons}>
        <Button size="small" onClick={onCancel}>
          {lang.CANCEL}
        </Button>
        <Button type="primary" size="small" htmlType="submit" disabled={!isValid || !dirty}>
          {lang.APPLY_CHANGES}
        </Button>
      </div>
    </form>
  ) : (
    <div className={styles.preview}>
      <div className={styles.previewTextWrapper}>
        <label className={styles.label}>{label}</label>
        {canEdit && (
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faPencil} />}
            size="small"
            loading={loading}
            onClick={() => setEditing(true)}
          />
        )}
      </div>
      <p className={classNames(styles.previewText, { [styles.previewTextDisabled]: loading })}>
        {initialValue || 'Unassigned'}
      </p>
    </div>
  );
}
