import { FEATURES } from '../../constants/features.constants';
import { isAccountAdmin, isCollaborator } from './auth.selector';
import {
  isKnowledgeHubAccessEnabled,
  isKnowledgeHubSharedEnabled,
  isOwnerPermissionsRestrictionEnabled,
} from './features.selector';
import { isAWorkspaceManager, userIsWorkspaceManager } from './workspace.selector';

/**
 * Check if the user have permissions and check feature restrictions
 * @param {ReduxState} { auth, organization }
 * @return {Boolean} if the user have permissions to edit the item
 */
export function hasPermissionsToEditItem({ auth, organization, workspace }) {
  if (userIsWorkspaceManager({ workspace, auth })) {
    return true;
  }
  const isAdmin = isAccountAdmin({ auth });
  const isFeatureEnabled = isOwnerPermissionsRestrictionEnabled(
    { auth, organization },
    FEATURES.PERMISSIONS_OWNER_RESTRICTION,
  );

  return isAdmin || !isFeatureEnabled;
}

/**
 * Check if the user have permissions to access knowledge hub
 * @param {ReduxState} { auth, organization }
 * @return {Boolean} if the user have permissions to see knowledge hub
 */
export function hasPermissionsToAccessKnowledgeHub({ auth, organization }) {
  const hasPermission = isCollaborator({ auth });
  const isFeatureEnabled = isKnowledgeHubAccessEnabled({ auth, organization });

  return hasPermission && isFeatureEnabled;
}

/**
 * Check if the user have permissions to share to knowledge hub
 * @param {ReduxState} { auth, organization }
 * @return {Boolean} if the user have permissions to share to knowledge hub
 */
export function hasPermissionsToShareToKnowledgeHub({ auth, organization }) {
  const hasPermission = isCollaborator({ auth });
  const isFeatureEnabled = isKnowledgeHubSharedEnabled({ auth, organization });

  return hasPermission && isFeatureEnabled;
}

/**
 * Check if user has permissions to see settings page
 * Account admins or collaborators that are workspace managers can see the settings page
 * @param {ReduxState} { auth, organization }
 * @return {Boolean} if the user have permissions to see settings page
 */
export function hasPermissionsToSeeSettings({ auth, workspace }) {
  const isAdmin = isAccountAdmin({ auth });
  const isManager = isAWorkspaceManager({ auth, workspace });

  return isAdmin || isManager;
}
