import { useCallback, useMemo } from 'react';
import {
  TAGS_SPECIALTY,
  PROFESSIONS,
  ROLES_ALL,
  LANGUAGES,
  TRAINING_LEVELS_ALL,
  USER_TAGS_KEYS,
  SPECIALTY,
  DIVISION,
  ROLES_BY_PROFESSION,
  LEVEL_OF_TRAINING_BY_ROLE,
  ALLOWED_PERMISSIONS_FOR_CHANGE,
} from '../constants';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { camelCaseToWords, sortByAlphabet } from '../utils';
import { nanoid } from 'nanoid';
import { useRotationOptions } from './profile.context';
import { getLabelsList } from '../store/selectors/labels.selector';
import _ from 'lodash';
import { getWorkspaces } from '../store/selectors/workspace.selector';

const useProfileTagsOption = options => {
  const LANG_TAGS = useSelector(getLang('TAGS'));
  const LANG_PROFILE = useSelector(getLang('PROFILE_OPTIONS'));
  const LANG_SPECIALTY = useSelector(getLang('SPECIALTY'));
  const LANG_DIVISION = useSelector(getLang('DIVISION'));
  const locations = useSelector(getLabelsList);

  const { stats = {}, showStats = true } = options;

  const createOptions = (values, labels, key, stats) => {
    let valuesToParse = values;

    if (!Array.isArray(values)) {
      valuesToParse = Object.values(values || {});
    }

    // If there are no stats for the key, return an empty array
    if (Object.keys(stats?.[key] || {}).length === 0) {
      return [];
    }

    return valuesToParse
      .filter(el => stats?.[key]?.[el])
      .map(el => {
        const keyStats = stats?.[key];
        const value = `${key}.${el}`;
        const label = `${labels?.[el] ?? el}${
          keyStats[el] && showStats ? ` (${keyStats[el]})` : ''
        }`;
        return { value, label, key: nanoid() };
      })
      .sort((a, b) => sortByAlphabet(a?.label, b?.label));
  };

  const useOptions = (values, labels, key, lang, stats) =>
    useMemo(
      () => createOptions(values, labels, key, stats),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [lang, stats, rotations],
    );

  const { rotations } = useRotationOptions();

  const departmentOptions = useOptions(
    TAGS_SPECIALTY,
    LANG_TAGS,
    USER_TAGS_KEYS.DEPARTMENT,
    LANG_TAGS,
    stats,
  );

  const rotationOptions = useOptions(
    rotations,
    LANG_TAGS,
    USER_TAGS_KEYS.ROTATION,
    LANG_TAGS,
    stats,
  );

  const professionOptions = useOptions(
    PROFESSIONS,
    LANG_PROFILE,
    USER_TAGS_KEYS.PROFESSION,
    LANG_PROFILE,
    stats,
  );

  const roleOptions = useOptions(ROLES_ALL, LANG_PROFILE, USER_TAGS_KEYS.ROLE, LANG_PROFILE, stats);

  const levelOfTrainingOptions = useOptions(
    TRAINING_LEVELS_ALL,
    LANG_PROFILE,
    USER_TAGS_KEYS.LEVEL_OF_TRAINING,
    LANG_PROFILE,
    stats,
  );

  const locationMap =
    locations?.reduce((obj, { code, name }) => {
      obj[code] = code;
      return obj;
    }, {}) || {};

  const locationLabels =
    locations?.reduce((obj, { code, name }) => {
      obj[code] = name;
      return obj;
    }, {}) || {};

  const locationOptions = useOptions(
    locationMap,
    locationLabels,
    USER_TAGS_KEYS.LOCATION,
    locationMap,
    stats,
  );

  const permissionsLabels = ALLOWED_PERMISSIONS_FOR_CHANGE.reduce((obj, permission) => {
    obj[permission] = camelCaseToWords(permission);
    return obj;
  }, {});

  const permissionsOptions = useOptions(
    ALLOWED_PERMISSIONS_FOR_CHANGE,
    permissionsLabels,
    USER_TAGS_KEYS.PERMISSIONS,
    permissionsLabels,
    stats,
  );

  const specialtyOptions = useOptions(
    SPECIALTY,
    LANG_SPECIALTY,
    USER_TAGS_KEYS.SPECIALTY,
    LANG_SPECIALTY,
    stats,
  );

  const divisionOptions = useOptions(
    DIVISION,
    LANG_DIVISION,
    USER_TAGS_KEYS.DIVISION,
    LANG_DIVISION,
    stats,
  );

  return {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOfTrainingOptions,
    locationOptions,
    permissionsOptions,
    specialtyOptions,
    divisionOptions,
  };
};

const useProfileOptionsField = () => {
  const LANG_PROFILE = useSelector(getLang('PROFILE_OPTIONS'));

  const createOptions = (values, labels, filterValue = null) => {
    let valuesToParse = values;

    if (!Array.isArray(values)) {
      valuesToParse = Object.values(values || {});
    }

    return valuesToParse
      .filter(el => (!filterValue ? el : el !== filterValue))
      .map(el => ({
        value: el,
        label: labels?.[el] ?? el,
      }))
      .sort((a, b) => sortByAlphabet(a?.label, b?.label));
  };

  const professionOptions = useMemo(() => createOptions(PROFESSIONS, LANG_PROFILE), [LANG_PROFILE]);

  const roleOptions = useMemo(() => createOptions(ROLES_ALL, LANG_PROFILE), [LANG_PROFILE]);

  const levelOftrainingOptions = useMemo(
    () => createOptions(TRAINING_LEVELS_ALL, LANG_PROFILE),
    [LANG_PROFILE],
  );

  return {
    professionOptions,
    roleOptions,
    levelOftrainingOptions,
  };
};

const useProfileOptions = ({ profession, role, department }) => {
  const LANG_TAGS = useSelector(getLang('TAGS'));
  const LANG_PROFILE = useSelector(getLang('PROFILE_OPTIONS'));
  const LANG_SPECIALTY = useSelector(getLang('SPECIALTY'));
  const LANG_DIVISION = useSelector(getLang('DIVISION'));

  const createOptions = (values, labels, filterValue = null) => {
    let valuesToParse = values;

    if (!Array.isArray(values)) {
      valuesToParse = Object.values(values || {});
    }

    return valuesToParse
      .filter(el => (!filterValue ? el : el !== filterValue))
      .map(el => ({
        id: el,
        value: labels?.[el] ?? el,
      }))
      .sort((a, b) => sortByAlphabet(a?.value, b?.value));
  };

  const departmentOptions = useMemo(() => createOptions(TAGS_SPECIALTY, LANG_TAGS), [LANG_TAGS]);

  const { rotations } = useRotationOptions();

  const rotationOptions = useMemo(
    () => createOptions(rotations, LANG_TAGS, department),
    [rotations, department, LANG_TAGS],
  );

  const professionOptions = useMemo(() => createOptions(PROFESSIONS, LANG_PROFILE), [LANG_PROFILE]);

  const roleOptions = useMemo(() => {
    const roles = ROLES_BY_PROFESSION.get(profession);

    return roles ? createOptions(roles, LANG_PROFILE) : null;
  }, [LANG_PROFILE, profession]);

  const levelOftrainingOptions = useMemo(() => {
    const levelOfTraining = LEVEL_OF_TRAINING_BY_ROLE.get(role);

    return levelOfTraining ? createOptions(levelOfTraining, LANG_PROFILE) : null;
  }, [LANG_PROFILE, role]);

  const timezoneOptions = createOptions(moment.tz.names());
  const languageOptions = useMemo(() => createOptions(LANGUAGES, LANG_PROFILE), [LANG_PROFILE]);
  const specialtyOptions = useMemo(
    () => createOptions(SPECIALTY, LANG_SPECIALTY),
    [LANG_SPECIALTY],
  );
  const divisionOptions = useMemo(() => createOptions(DIVISION, LANG_DIVISION), [LANG_DIVISION]);

  return {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOftrainingOptions,
    timezoneOptions,
    languageOptions,
    specialtyOptions,
    divisionOptions,
  };
};

const useProfileLang = () => {
  const profileLang = useSelector(getLang('PROFILE_OPTIONS'));
  const tagsLang = useSelector(getLang('TAGS'));
  const divisionLang = useSelector(getLang('DIVISION'));
  const specialtyLang = useSelector(getLang('SPECIALTY'));
  const locations = useSelector(getLabelsList);
  const workspaces = useSelector(getWorkspaces);

  const userProfileOptions = useMemo(
    () => ({
      ...profileLang,
      ...tagsLang,
      ...divisionLang,
      ...specialtyLang,
    }),
    [profileLang, tagsLang, divisionLang, specialtyLang],
  );

  const locationsOptions = useMemo(() => {
    return (
      locations
        ?.sort((a, b) => sortByAlphabet(a.name, b.name))
        ?.map(({ code, name }) => ({ code, name })) ?? []
    );
  }, [locations]);

  const workspacesOptions = useMemo(() => {
    return (
      workspaces
        ?.sort((a, b) => sortByAlphabet(a.name, b.name))
        ?.map(({ id, name }) => ({ id, name })) ?? []
    );
  }, [workspaces]);

  const getWorkspaceName = useCallback(
    id => workspacesOptions.find(({ id: workspaceId }) => workspaceId === id)?.name,
    [workspacesOptions],
  );

  const getWorkspaceId = useCallback(
    name => workspacesOptions.find(({ name: workspaceName }) => workspaceName === name)?.id,
    [workspacesOptions],
  );

  const getValueFromLang = useCallback(
    value => userProfileOptions[value] ?? value ?? '-',
    [userProfileOptions],
  );

  const getMultipleValuesFromLang = useCallback(
    list => {
      if (_.isEmpty(list)) return '-';
      return (
        list
          ?.map(value => userProfileOptions[value])
          ?.sort((a, b) => sortByAlphabet(a, b))
          ?.join(', ') ?? '-'
      );
    },
    [userProfileOptions],
  );

  const getLocationsValues = useCallback(
    list => {
      if (_.isEmpty(list)) return '-';
      return (
        list
          ?.map(value => locationsOptions.find(({ code }) => code === value)?.name)
          ?.sort((a, b) => sortByAlphabet(a, b))
          ?.join(', ') ?? '-'
      );
    },
    [locationsOptions],
  );

  const getWorkspaceValues = useCallback(
    value => getWorkspaceName(value) ?? '-',
    [getWorkspaceName],
  );

  return {
    getValueFromLang,
    getMultipleValuesFromLang,
    getLocationsValues,
    getWorkspaceValues,
    getWorkspaceName,
    getWorkspaceId,
    userProfileOptions,
    locationsOptions,
    workspacesOptions,
  };
};

export { useProfileOptions, useProfileTagsOption, useProfileLang, useProfileOptionsField };
