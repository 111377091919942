import styles from '../workspace-general-info.module.scss';
import { useState } from 'react';
import { Button } from 'src/components/design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Owners } from 'src/components/common/owners';
import classNames from 'classnames';

export function WorkspaceManagers({ lang, workspaceManagers = [], applyChanges = () => {} }) {
  const [managers, setManagers] = useState(workspaceManagers);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const onApplyChanges = async values => {
    setEditing(false);
    setLoading(true);
    await applyChanges(values);
    setLoading(false);
  };

  const onCancel = () => {
    setEditing(false);
    setManagers(workspaceManagers);
  };

  return (
    <div className={styles.managers}>
      <div className={styles.managersPreview}>
        <label className={styles.managersLabel}>{lang.WORKSPACE_MANAGERS}</label>
        {!editing && (
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faPencil} />}
            size="small"
            loading={loading}
            onClick={() => setEditing(true)}
          />
        )}
      </div>
      {editing ? (
        <>
          <MultipleOwnersSelector
            showAllUsers
            value={managers}
            placeholder={lang.WORKSPACE_MANAGERS}
            name="managers"
            onChange={(f, v) => setManagers(v || [])}
            borderRadius={4}
            bordered
            size="large"
            disableAddOwner
            disableAutoFill
          />
          <div className={styles.submitButtons}>
            <Button size="small" onClick={onCancel}>
              {lang.CANCEL}
            </Button>
            <Button
              type="primary"
              size="small"
              disabled={false}
              onClick={() => onApplyChanges(managers)}
            >
              {lang.APPLY_CHANGES}
            </Button>
          </div>
        </>
      ) : workspaceManagers?.length > 0 ? (
        <Owners
          disabledClassName={classNames({ [styles.disabledOwners]: loading })}
          ownerIds={workspaceManagers}
        />
      ) : (
        <span className={styles.noOwner}>{lang.UNASSIGNED}</span>
      )}
    </div>
  );
}
