import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom-v5-compat';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';
import { getWorkspaces } from 'shared/store/selectors/workspace.selector';

const WorkspacesContext = createContext();

/**
 * Get the current workspace and workspaces list from the context and the ability to change the workspace
 * @returns {{
 *  onChangeWorkspace: (workspace: import('../types').Workspace, options?: { setSearchParams: boolean }),
 *  onDeleteWorkspace: () => void,
 *  workspace: import('../types').Workspace,
 *  workspaces: import('../types').Workspace[],
 *  isNew: boolean
 * }}
 */
export function useWorkspacesContext() {
  return useContext(WorkspacesContext);
}

export const WORKSPACE_SEARCH_PARAMS = Object.freeze({
  WORKSPACE_ID: 'wid',
  QUICK_LINK_INDEX: 'ql',
  SCHEDULE_URL_INDEX: 'su',
  COPY_SETTINGS: 'copy',
  MODE: 'mode',
});

const isCreateMode = id => id?.startsWith('new-') || false;

export function WorkspacesProvider({ children }) {
  const workspaces = useSelector(getWorkspaces);
  const [workspace, setWorkspace] = useState(null);
  const userId = useSelector(state => state.auth.user.id);
  const isAdmin = useSelector(isAccountAdmin);

  const managedWorkspaces = useMemo(
    () => (isAdmin ? workspaces : workspaces?.filter(w => w?.managers?.includes(userId))),
    [isAdmin, workspaces, userId],
  );

  const [queryParams, setQueryParams] = useSearchParams();
  const location = useLocation();

  const onChangeWorkspace = useCallback(
    (workspace, options = {}) => {
      const { setSearchParams = false } = options;
      if (setSearchParams) {
        const isNew = isCreateMode(workspace.id);
        setQueryParams(
          new URLSearchParams({
            [WORKSPACE_SEARCH_PARAMS.WORKSPACE_ID]: workspace.id,
          }),
          { replace: true, state: isNew ? { newWorkspace: workspace } : null },
        );
      }
      setWorkspace(workspace);
    },
    [setQueryParams],
  );

  const selected = queryParams.get(WORKSPACE_SEARCH_PARAMS.WORKSPACE_ID);

  const isNew = isCreateMode(selected);

  useEffect(() => {
    if (!selected && managedWorkspaces?.length > 0) {
      // If no workspace is selected, select the first one
      onChangeWorkspace(managedWorkspaces[0], { setSearchParams: true });
      return;
    }

    // handle refresh
    if (selected && managedWorkspaces?.length > 0 && !workspace) {
      const selectedWorkspace = managedWorkspaces.find(workspace => workspace.id === selected);
      if (selectedWorkspace) {
        setWorkspace(selectedWorkspace);
      } else if (isNew && location.state?.newWorkspace) {
        setWorkspace(location.state.newWorkspace);
      }
    }
  }, [
    managedWorkspaces,
    selected,
    setQueryParams,
    onChangeWorkspace,
    workspace,
    location.state,
    isNew,
  ]);

  const onDeleteWorkspace = () => {
    onChangeWorkspace(workspaces[0], { setSearchParams: true });
  };

  const value = {
    onChangeWorkspace,
    onDeleteWorkspace,
    workspace,
    workspaces: managedWorkspaces,
    isNew,
  };

  return (
    <WorkspacesContext.Provider value={value}>
      <>{children}</>
    </WorkspacesContext.Provider>
  );
}
