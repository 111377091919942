import { useDispatch, useSelector } from 'react-redux';
import {
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
} from 'shared/store/actions/workspace.actions';
import _ from 'lodash';

export function useWorkspaceApi() {
  const dispatch = useDispatch();
  const organizationId = useSelector(state => state.organization.id);

  return {
    /**
     * Create a new workspace
     * @param {import('../types').Workspace} workspace
     * @returns {Promise<import('../types').Workspace>}
     */
    createWorkspace: async workspace => {
      // remove empty fields
      Object.entries(workspace).forEach(([key, value]) => {
        if (_.isEmpty(value)) {
          delete workspace[key];
        }
      });
      Object.entries(workspace.operational).forEach(([key, value]) => {
        if (_.isEmpty(value)) {
          delete workspace.operational[key];
        }
      });

      const { name, operational } = workspace;

      // Create a new workspace
      return await dispatch(
        createWorkspace({
          organizationId,
          name,
          operational,
        }),
      );
    },

    /**
     * Update the name of the workspace
     * @param {string} workspaceId the workspace id
     * @param {string} name the new name of the workspace
     */
    updateName: async (workspaceId, name) => {
      // Update the name of the workspace
      return await dispatch(
        updateWorkspace({
          workspaceId,
          name,
        }),
      );
    },

    /**
     * Update the operational data of the workspace
     * @param {string} workspaceId the workspace id
     * @param {import('../types').Workspace} workspaceData the new operational data including the name of the workspace
     * @returns {Promise<void>}
     */
    updateOperational: async (workspaceId, { name, operational }) => {
      // Update the operational data of the workspace
      return await dispatch(
        updateWorkspace({
          workspaceId,
          name,
          operational,
        }),
      );
    },

    /**
     * Update managers of the workspace
     * @param {string} workspaceId the workspace id
     * @param {string[]} managers the list of manager ids
     * @returns {Promise<void>}
     */
    updateManagers: async (workspaceId, managers) => {
      // Update the managers of the workspace
      return dispatch(
        updateWorkspace({
          workspaceId,
          managers,
        }),
      );
    },

    /**
     * Delete a workspace
     * @param {string} workspaceId the workspace id to delete
     * @returns {Promise<void>}
     */
    deleteWorkspace: async workspaceId => {
      // Delete the workspace
      return await dispatch(deleteWorkspace(workspaceId));
    },
  };
}
