import { useEffect } from 'react';
import { useKnowledgeHub } from '../hooks/use-knowledge-hub.hooks';
import styles from './components.module.scss';
import { Loader } from 'src/components/core';
import { ORGANIZATIONS_LOGO_URL } from 'shared/constants';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

export function RenderOrganizationList() {
  const lang = useSelector(getLang('KNOWLEDGE_HUB'));

  const {
    onSelectOrganization,
    getOrganizations,
    organizationsList: list,
    organizationId,
    loading,
    analyticsTriggers,
  } = useKnowledgeHub();

  useEffect(() => {
    getOrganizations();

    analyticsTriggers?.onPageLoad();
  }, [getOrganizations, analyticsTriggers]);

  if (organizationId) return null;

  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.content}>
      {list.map(org => {
        const department = org.department ? `${lang.DEPARTMENT} ${org.department}` : '';
        const healthSystem = org.healthSystem ? org.healthSystem : org.name;
        const logo = ORGANIZATIONS_LOGO_URL + 'hub/' + org.logo;
        const fallbackLogo = ORGANIZATIONS_LOGO_URL + org.logo;

        return (
          <div
            id={org.id}
            key={org.id}
            className={styles.organizationWrapper}
            onClick={onSelectOrganization}
          >
            <img
              className={styles.organizationIcon}
              src={logo}
              onError={event => {
                event.target.src = fallbackLogo;
                event.onerror = null;
              }}
              title={org.name}
              alt={org.name}
            />
            {department && <p className={styles.title}>{department}</p>}
            <p className={department ? styles.subtitle : styles.title}>{healthSystem}</p>
          </div>
        );
      })}
    </div>
  );
}
