import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  colorPalette,
  theme,
  Lang,
  PROFESSIONS,
  ROLES_ALL,
  TAGS_SPECIALTY,
  TRAINING_LEVELS_ALL,
  USER_PERMISSIONS,
  USER_TAGS_KEYS,
  KNOWLEDGE_RESOURCES_TYPES,
  SPECIALTY,
  DIVISION,
} from 'shared/constants';
import styles from './communication-center.module.scss';
import { ConfigProvider, Modal, notification, Switch, Upload } from 'antd';
import {
  Divider,
  ScreenTitle,
  Button,
  TextInput,
  TextAreaInput,
} from 'src/components/design-system';
import classNames from 'classnames';
import { RecipientsGroupSelector } from './components/recipients-group-selector';
import { faEllipsisVertical, faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { SelectContent } from 'src/components/core/select-content';
import { ALLOWED_MIME_TYPES } from 'shared/constants/assets.constants';
import { ResourceList } from 'src/components/core/select-content/resource-list.component';
import { useKnowledgeParser } from 'shared/utils/rotations';
import { IndividualsSelector } from './components/recipients-group-selector/individuals-selector';
import { TotalRecipientsProvider } from './components/recipients-group-selector/total-recipients.context';
import { useSelector } from 'react-redux';
import { isWorkingHoursNow } from 'shared/store/selectors/organization.selector';
import { LearnMore } from 'src/components/common/learn-more';

const TITLE_MAX_LENGTH = 40;
const OPTIONS_DEFAULTS = {
  onWorkingHours: false,
  sendByEmail: true,
};

const MESSAGE_SCHEMA = Yup.object().shape({
  title: Yup.string().min(2).max(TITLE_MAX_LENGTH).required('Required'),
  content: Yup.string().min(2).required('Required'),
  options: Yup.object().shape({
    onWorkingHours: Yup.boolean(),
    sendByEmail: Yup.boolean(),
  }),
  recipientsGroups: Yup.array().of(
    Yup.object().shape({
      [USER_TAGS_KEYS.DEPARTMENT]: Yup.string().oneOf(Object.values(TAGS_SPECIALTY)),
      [USER_TAGS_KEYS.ROTATION]: Yup.string(),
      [USER_TAGS_KEYS.ROLE]: Yup.string().oneOf(Object.values(ROLES_ALL)),
      [USER_TAGS_KEYS.LEVEL_OF_TRAINING]: Yup.string().oneOf(Object.values(TRAINING_LEVELS_ALL)),
      [USER_TAGS_KEYS.PROFESSION]: Yup.string().oneOf(Object.values(PROFESSIONS)),
      [USER_TAGS_KEYS.LOCATION]: Yup.string(),
      [USER_TAGS_KEYS.DIVISION]: Yup.string().oneOf(Object.values(DIVISION)),
      [USER_TAGS_KEYS.SPECIALTY]: Yup.string().oneOf(Object.values(SPECIALTY)),
      [USER_TAGS_KEYS.PERMISSIONS]: Yup.string().oneOf(Object.values(USER_PERMISSIONS)),
    }),
  ),
  individuals: Yup.array().of(Yup.string()),
  attachments: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      url: Yup.string(),
      title: Yup.string().required(),
      type: Yup.string().required(),
      createdBy: Yup.string().required(),
    }),
  ),
});

const CommunicationCenterPageComponent = ({
  lang,
  sendNotification,
  uploadFile,
  userId,
  knowledge,
  admission,
  lab,
  organizationStartWorkingHours = 7,
}) => {
  const [isLoading, setLoadingState] = useState(false);
  const [formSendDate, setFormSendDate] = useState(Date.now());
  const [isSelectContentOpen, setSelectContentOpen] = useState(false);
  const [isLoadingFile, setLoadingFile] = useState(false);
  const [api, contextHolder] = notification.useNotification({ placement: 'bottomRight' });

  const { parse } = useKnowledgeParser({ knowledge, admission, lab });

  const addAttachment = payload =>
    setFieldValue('attachments', [...values.attachments, { ...payload, createdBy: userId }]);
  const removeAttachment = id =>
    setFieldValue(
      'attachments',
      values.attachments.filter(a => a.id !== id),
    );

  const handleUploadFile = async ({ file }) => {
    try {
      setLoadingFile(true);
      const res = await uploadFile({ file });

      if (res.error) {
        api.error({
          message: res.error,
        });
        return;
      }
      addAttachment({
        url: res.url,
        title: file.name,
        type: KNOWLEDGE_RESOURCES_TYPES.FILE,
      });

      setLoadingFile(false);
    } catch (err) {
      api.error({
        message: err.message,
      });
    }
  };

  const onSubmit = async (data, { resetForm }) => {
    setLoadingState(true);

    try {
      const res = await sendNotification({ data });

      if (res.error) {
        api.error({
          message: lang.NOTIFICATION_SENT_ERROR,
        });
        return;
      }

      // Clean form on success
      resetForm();
      setFormSendDate(Date.now());
      api.success({
        message: lang.NOTIFICATION_SENT_SUCCESS,
        placement: 'bottomRight',
      });
    } catch (err) {
      api.error({
        message: err.message,
      });
    } finally {
      setLoadingState(false);
    }
  };

  const handleSelectContent = async payload => {
    addAttachment(payload);
    setSelectContentOpen(false);
  };

  const { handleChange, handleSubmit, values, errors, setFieldValue, isValid, dirty } = useFormik({
    validationSchema: MESSAGE_SCHEMA,
    enableReinitialize: true,
    initialValues: {
      options: OPTIONS_DEFAULTS,
      recipientsGroups: [{}], // Set one empty group by default
      individuals: [],
      attachments: [],
    },
    onSubmit,
  });

  const setOptionsCheckboxState = key => checked => {
    setFieldValue(`options.${key}`, checked);
  };

  const isWorkHoursNow = useSelector(isWorkingHoursNow);

  return (
    <>
      {contextHolder}
      <div className={classNames('new-design-system', styles.root)} key={formSendDate}>
        <div className={styles.header}>
          <ScreenTitle title={lang.HEADER} />
        </div>

        <form onSubmit={handleSubmit}>
          <div className={styles.multiInput}>
            <TotalRecipientsProvider
              individuals={values.individuals}
              groups={values.recipientsGroups}
            >
              <RecipientsGroupSelector
                groups={values.recipientsGroups}
                name="recipientsGroups"
                onChange={setFieldValue}
                error={errors.recipientsGroups}
              />

              <Divider dashed />

              <IndividualsSelector
                value={values.individuals}
                name="individuals"
                error={errors.individuals}
                onChange={setFieldValue}
              />
            </TotalRecipientsProvider>

            <Divider dashed />

            <TextInput
              name="title"
              className={styles.title}
              value={values.title}
              error={errors.title}
              onChange={handleChange}
              placeholder={lang.TITLE_PLACEHOLDER}
              bordered={false}
              size="large"
              maxLength={TITLE_MAX_LENGTH}
            />

            <Divider />

            <TextAreaInput
              name="content"
              value={values.content}
              error={errors.content}
              className={styles.content}
              onChange={handleChange}
              placeholder={lang.CONTENT_PLACEHOLDER}
              bordered={false}
              size="medium"
              rows={10}
              style={{ resize: 'none' }}
            />

            <ResourceList
              resources={values.attachments.map(parse)}
              canEdit
              backgroundColor={colorPalette.neutral2}
              doRemove={removeAttachment}
              showNotFoundPanda={false}
              wrapperClass={styles.resourceItem}
              openInNewTab
            />

            <Divider className={styles.footerDivider} />

            <div className={styles.footer}>
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.button}
                  size="medium"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={!isValid || isLoadingFile || !dirty}
                >
                  <FontAwesomeIcon icon={faPaperPlaneTop} className={styles.sendIcon} />
                  {lang.SEND}
                </Button>
                <Button
                  className={styles.button}
                  size="medium"
                  type="default"
                  disabled={isLoading}
                  onClick={() => setSelectContentOpen(true)}
                >
                  <FontAwesomeIcon icon={faLink} className={styles.sendIcon} />
                  {lang.ADD_RESOURCE}
                </Button>
                {
                  // TODO: see antd Upload and Dragger, might be useful to implement drag and drop functionality
                }
                <Upload
                  showUploadList={false}
                  accept={Object.values(ALLOWED_MIME_TYPES).join(',')}
                  customRequest={handleUploadFile}
                >
                  <Button
                    className={styles.button}
                    size="medium"
                    type="default"
                    loading={isLoadingFile}
                    disabled={isLoading}
                  >
                    <FontAwesomeIcon icon={faPaperclip} className={styles.sendIcon} />
                    {lang.ATTACH}
                  </Button>
                </Upload>
              </div>
              <div className={styles.toggles}>
                {!isWorkHoursNow && (
                  <>
                    <div className={styles.toggleWrapper}>
                      <Switch
                        size="medium"
                        defaultChecked={OPTIONS_DEFAULTS.onWorkingHours}
                        checked={values.options.onWorkingHours}
                        onChange={setOptionsCheckboxState('onWorkingHours')}
                      />
                      <p className={styles.checkLabel}>
                        {lang.ON_WORKING_HOURS.replace(
                          '{START_HOUR}',
                          organizationStartWorkingHours,
                        )}
                      </p>
                    </div>
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      size="xl"
                      color={colorPalette.neutral5}
                    />
                  </>
                )}
                <div className={styles.toggleWrapper}>
                  <Switch
                    size="medium"
                    defaultChecked={OPTIONS_DEFAULTS.sendByEmail}
                    checked={values.options.sendByEmail}
                    onChange={setOptionsCheckboxState('sendByEmail')}
                  />
                  <p className={styles.checkLabel}>{lang.SEND_BY_EMAIL}</p>

                  <LearnMore
                    link="https://help.c8health.com/sending-a-message-to-users"
                    text={lang.LEARN_MORE_SEND_EMAIL}
                  />
                </div>
              </div>
            </div>
            <input type="file" style={{ display: 'none' }} onChange={() => {}} />
          </div>
        </form>
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                contentBg: colorPalette.neutral2,
                headerBg: colorPalette.neutral2,
                fontFamily: theme.newFontFamily,
                fontSizeHeading2: theme.modalHeaderFontSize,
              },
            },
          }}
        >
          <Modal
            open={isSelectContentOpen}
            footer={null}
            width={1000}
            centered
            maskClosable={false}
            destroyOnClose
            closable={false}
            title={<h2>{lang.ADD_RESOURCE}</h2>}
          >
            <SelectContent
              disabledIds={values.attachments.map(a => a.id)}
              onCancel={() => {
                setSelectContentOpen(false);
              }}
              onSubmit={handleSelectContent}
            />
          </Modal>
        </ConfigProvider>
      </div>
    </>
  );
};

CommunicationCenterPageComponent.defaultProps = {
  lang: Lang.COMMUNICATION_CENTER,
  sendNotification: () => {},
};

export { CommunicationCenterPageComponent };
