import React from 'react';
import { NoContent } from '../../common';
import { Loader } from '../../core';
import { NewsFeedItem } from '../news-feed-item';
import styles from './news-feed-list.module.scss';
import { Divider } from 'src/components/design-system';
import { PandaResting } from 'src/images';

export function NewsFeedListComponent({ items, lang }) {
  const renderItems = () => {
    if (items === null) {
      return <Loader />;
    }

    if (items?.length === 0) {
      return (
        <NoContent text={lang.NO_ITEMS_MESSAGE} image={PandaResting} withMixBlendMode={false} />
      );
    }

    return items.map(item => <NewsFeedItem key={item.id} {...item} />);
  };

  return (
    <>
      <p className={styles.title}>{lang.TITLE}</p>

      <Divider className={styles.divider} />

      <div className={styles.items}>{renderItems()}</div>
    </>
  );
}
