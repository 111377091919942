import { connect } from 'react-redux';
import { HeaderComponent } from './header.component';
import { logout } from 'shared/store/actions/auth.actions';
import { setAdminMode } from 'shared/store/actions/application.actions';
import { isAccountAdmin, isCollaborator } from 'shared/store/selectors/auth.selector';
import { isSwitchOrganizationAvailable } from 'shared/store/selectors/available-organizations.selector';
import { selectWorkspace } from 'shared/store/actions/workspace.actions';
import { getSelectedWorkspaceId, getWorkspaces } from 'shared/store/selectors/workspace.selector';
import { isAIEnabled } from 'shared/store/selectors/features.selector';
import { isNormalUser } from 'shared/store/selectors/user.selector';
import { isAWorkspaceManager } from 'shared/store/selectors/workspace.selector';

const mapStateToProps = ({
  organization,
  auth,
  application,
  availableOrganizations,
  lang,
  generalConfig,
  workspace,
}) => {
  const isAdmin = isAccountAdmin({ auth });
  const isWSManager = isAWorkspaceManager({ auth, workspace });
  return {
    lang: lang.HEADER,
    organization,
    user: auth.user,
    adminMode: application.adminMode,
    isCollaborator: isCollaborator({ auth }),
    isAccountAdmin: isAdmin,
    isSwitchOrganizationAvailable: isSwitchOrganizationAvailable({
      availableOrganizations,
    }),
    feedbackForm: generalConfig.FEEDBACK_FORM,
    workspaces: getWorkspaces({ workspace }),
    selectedWorkspaceId: getSelectedWorkspaceId({ workspace }),
    isAIEnabled: isAIEnabled({ organization, auth }),
    isCanEditProfile: isNormalUser({ auth }),
    canAccessSettings: isAdmin || isWSManager,
  };
};

export const Header = connect(mapStateToProps, {
  logout,
  setAdminMode,
  selectWorkspace,
})(HeaderComponent);
