import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ConfigProvider, Table } from 'antd';
import { useResizeDetector } from 'react-resize-detector';
import { Input, Loader } from '../core';
import styles from './user-list.module.scss';
import _ from 'lodash';
import { UserDetailsComponent } from '../user-details';
import { useHistory, useLocation } from 'react-router';
import { formatFullName, normalizedContains, removeDiacritics } from 'shared/utils';
import { Button, ScreenTitle } from '../design-system';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getLang } from 'shared/store/selectors/lang.selector';
import { colorPalette } from 'shared/constants';
import { isCollaborator } from 'shared/store/selectors/auth.selector';

const UserList = React.memo(function UserList({
  users,
  columns,
  onDelete,
  onTransferOwnership,
  openNotification,
  notificationContextHolder,
  pageTitle,
  buttonText,
  onAdd = null,
  renderDetails,
  baseRoute,
  detailsRouteQueryParam,
  analyticsTriggers,
  multiSelect = false,
  openPreview = null,
}) {
  const lang = useSelector(getLang('USERS_MANAGEMENT'));
  const history = useHistory();
  const { search } = useLocation();
  const [userDetails, setUserDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = useRef(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [resultCount, setResultCount] = useState(users?.length ?? 0);
  const isAllowedToAdd = useSelector(isCollaborator);
  const { width, height = 0, ref } = useResizeDetector();

  useEffect(() => {
    setResultCount(users?.length);
  }, [users]);

  const openDetails = useCallback(
    id => {
      analyticsTriggers?.openDetails({ id });

      if (openPreview) {
        openPreview(id);
      } else {
        history.push(`${baseRoute}?${detailsRouteQueryParam}=${id}`);
      }
    },
    [analyticsTriggers, baseRoute, detailsRouteQueryParam, history, openPreview],
  );

  const closeDetails = useCallback(() => {
    history.push(baseRoute);
    setDetailsOpen(false);
  }, [baseRoute, history]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get(detailsRouteQueryParam);
    if (id) {
      setUserDetails(users?.find(user => user.id === id));
      setDetailsOpen(true);
    } else {
      setUserDetails([]);
    }
  }, [search, users, detailsRouteQueryParam]);

  const onSearchTermChanged = e => setSearchTerm(removeDiacritics(e?.target?.value));

  const filterAction = ({ email, firstName, lastName, name }) =>
    normalizedContains(email, searchTerm) ||
    normalizedContains(firstName, searchTerm) ||
    normalizedContains(lastName, searchTerm) ||
    normalizedContains(name, searchTerm);

  const onRowClick = useCallback(
    record => ({
      onClick: e => {
        e.stopPropagation();
        openDetails(record?.id);
      },
    }),
    [openDetails],
  );

  const onSelectRows = useCallback((selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  }, []);

  const rowSelection = { onChange: onSelectRows, selectedRowKeys, columnWidth: 50 };

  const onClickAdd = useCallback(() => {
    history.push(baseRoute);

    setDetailsOpen(true);
    setUserDetails([]);

    if (!!onAdd) {
      onAdd();
    }
  }, [onAdd, history, baseRoute]);

  const onClickEdit = useCallback(() => {
    if (selectedRows.length === 1) {
      openDetails(selectedRows[0].id);
    }
    setUserDetails(selectedRows);
    setDetailsOpen(true);
  }, [selectedRows, openDetails]);

  const filteredUsers = users
    ?.map(user => ({
      ...user,
      name: formatFullName(user, {
        labelInParenthesis: user?.isDeactivated ? lang.DEACTIVATED : null,
      }),
      ...user.professional,
    }))
    ?.filter(filterAction);

  const onChange = useCallback(
    (pagination, filters, sorter, extra) => {
      // when using filters removed selected rows
      if (selectedRowKeys.length !== 0 && Object.values(filters).length !== 0) {
        setSelectedRowKeys([]);
        setSelectedRows([]);
      }
      setFilteredInfo(filters);
      setResultCount(extra.currentDataSource?.length ?? 0);
    },
    [selectedRowKeys],
  );

  const usedFilters = Object.keys(filteredInfo)
    .reduce((acc, key) => {
      if (filteredInfo[key]?.length > 0) {
        const fieldName = columns.find(column => column.dataIndex === key)?.title;
        return [...acc, fieldName];
      }
      return acc;
    }, [])
    .join(', ');

  useEffect(() => {
    if (Object.values(filteredInfo).length === 0) {
      setResultCount(filteredUsers?.length);
    }
  }, [filteredUsers, filteredInfo]);

  if (_.isNull(users)) {
    return <Loader />;
  }

  const renderUserDetails = () => {
    if (!!renderDetails) return renderDetails();

    return (
      <UserDetailsComponent
        user={userDetails}
        close={closeDetails}
        open={detailsOpen}
        onDeleteUser={onDelete}
        onTransferOwnership={onTransferOwnership}
        openNotification={openNotification}
      />
    );
  };

  return (
    <>
      {notificationContextHolder}
      {renderUserDetails()}
      <div className={classNames(['new-design-system', styles.root])}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>
              <ScreenTitle title={pageTitle} />
              {isAllowedToAdd && (
                <Button className={styles.button} type="primary" onClick={onClickAdd}>
                  {buttonText}
                </Button>
              )}
              {selectedRowKeys.length > 0 && (
                <Button className={styles.button} ghost type="primary" onClick={onClickEdit}>
                  {lang.EDIT_USER.replace('{number}', selectedRowKeys.length ?? '')}
                </Button>
              )}
            </div>
            <div className={styles.results}>
              {resultCount > 0 && <h4>{lang.SHOWING_RESULTS.replace('{count}', resultCount)}</h4>}
              {usedFilters?.length > 0 && (
                <div className={styles.filterWrapper}>
                  <h5>{`${lang.FILTERED_BY} ${usedFilters}`}</h5>
                </div>
              )}
            </div>
          </div>
          <div className={styles.search}>
            <Input
              className={styles.input}
              onChange={onSearchTermChanged}
              placeholder={lang.SEARCH_PLACEHOLDER}
            />
          </div>
        </div>
        <div className={styles.tableContainer} ref={ref}>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBorderColor: colorPalette.neutral4,
                },
              },
            }}
          >
            {/* 75 is roughly the height of the header */}
            <Table
              virtual
              ref={tableRef}
              className={styles.table}
              pagination={false}
              scroll={{ x: width, y: Math.max(1, height - 75) }}
              columns={columns}
              dataSource={filteredUsers}
              onRow={onRowClick}
              rowClassName={styles.row}
              rowKey={record => `row-${record.id}`}
              defaultSortField="lastName"
              rowSelection={multiSelect ? rowSelection : null}
              onChange={onChange}
            />
          </ConfigProvider>
        </div>
      </div>
    </>
  );
});

export { UserList };
