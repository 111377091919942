import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';
import styles from './settings.module.scss';
import { Divider, SectionTitle } from 'src/components/design-system';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { Link } from 'react-router-dom-v5-compat';
import { isAccountAdmin, isRoot } from 'shared/store/selectors/auth.selector';
import { isAWorkspaceManager } from 'shared/store/selectors/workspace.selector';

export function AllSettingsButton() {
  const lang = useSelector(getLang('SETTINGS'));

  return (
    <Link to={ROUTES.SETTINGS} className={styles.allSettingButton}>
      {lang.TITLE}
    </Link>
  );
}

export const SettingsPage = () => {
  const lang = useSelector(getLang('SETTINGS'));

  const canViewMagicLink = useSelector(isRoot);
  const isAdmin = useSelector(isAccountAdmin);
  const isWSManager = useSelector(isAWorkspaceManager);
  const canViewWorkspaceManagement = isAdmin || isWSManager;

  const routes = useMemo(
    () => [
      {
        path: ROUTES.SETTINGS_WORKSPACES,
        title: lang.WORKSPACE_TITLE,
        subtitle: lang.WORKSPACE_SUBTITLE,
        hide: !canViewWorkspaceManagement,
      },
      {
        path: ROUTES.SETTINGS_MAGIC_LINK,
        title: lang.MAGIC_LINK,
        subtitle: lang.MAGIC_LINK_SUBTITLE,
        hide: !canViewMagicLink,
      },
    ],
    [lang, canViewMagicLink, canViewWorkspaceManagement],
  );

  return (
    <div className={classNames(styles.root, 'new-design-system')}>
      {routes.map((route, index) => {
        return route.hide ? null : (
          <NavLink
            exact
            className={styles.menuRoot}
            activeClassName={styles.active}
            key={route.path}
            to={route.path}
          >
            <div className={styles.viewButton}>
              <SectionTitle title={route.title} />
              <p className={styles.subtitle}>{route.subtitle}</p>
            </div>
            {index < routes.length - 1 && <Divider />}
          </NavLink>
        );
      })}
    </div>
  );
};
