import React from 'react';
import { Button } from 'src/components/design-system';
import { Select } from 'src/components/design-system/forms';
import { NotFound } from 'src/components/common';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { notification, Tooltip } from 'antd';
import styles from './team-manager.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { ShareBoxComponent } from '../share';
import { formatFullName } from 'shared/utils';
import { ROUTES } from 'shared/constants';

const TeamManager = ({
  existingUsers = [],
  availableUsers = [],
  onAddUsers = () => {},
  onRemoveUser = () => {},
  skipRemoveUserToast = false,
  onClickRow = () => {},
  canEdit = false,
  notFoundText = '',
  disabledIds = [],
}) => {
  const userLang = useSelector(getLang('CONTACT_SCREEN'));
  const roleLang = useSelector(getLang('PROFILE_OPTIONS'));
  const departmentLang = useSelector(getLang('TAGS'));
  const lang = useSelector(getLang('TEAM_MANAGER'));
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [loadingAdd, setLoadingAdd] = React.useState(false);
  const [removingId, setRemovingId] = React.useState(null);
  const [api, contextHolder] = notification.useNotification({ placement: 'bottomRight' });

  const handleAddUsers = async () => {
    setLoadingAdd(true);
    try {
      const res = await onAddUsers(selectedUsers);
      if (res?.error) {
        api.error({ message: res.error || lang.ADD_FAILED });
      } else {
        api.success({ message: lang.ADD_SUCCESSFUL });
      }
      setSelectedUsers([]);
    } catch (error) {
      api.error({ message: error.message || lang.ADD_FAILED });
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleRemoveUser = async userId => {
    if (removingId === userId) return;
    setRemovingId(userId);
    try {
      await onRemoveUser(userId);
      !skipRemoveUserToast && api.success({ message: lang.REMOVE_SUCCESSFUL });
    } catch (error) {
      api.error({ message: error.message || lang.REMOVE_FAILED });
    } finally {
      setRemovingId(null);
    }
  };

  return (
    <div className={styles.root}>
      {contextHolder}
      {canEdit && (
        <div className={styles.addNew}>
          <Select
            multiple
            size="large"
            onChange={(f, value) => setSelectedUsers(value)}
            options={availableUsers}
            value={selectedUsers}
          />
          <Button
            type="primary"
            onClick={handleAddUsers}
            size="large"
            disabled={loadingAdd || !selectedUsers.length}
          >
            {userLang.ADD}
          </Button>
        </div>
      )}
      {existingUsers.length ? (
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <h4 className={styles.column}>{userLang.NAME}</h4>
            <h4 className={styles.column}>{userLang.PHONE}</h4>
            <h4 className={styles.column}>{userLang.PAGER}</h4>
            <h4 className={styles.column}>{userLang.CISCO}</h4>
            <h4 className={styles.column}>{userLang.EMAIL}</h4>
            {canEdit && <h4 className={styles.actionColumn}>{userLang.ACTIONS}</h4>}
          </div>

          {existingUsers
            .filter(user => !!user)
            .map(user => {
              const { id, firstName, lastName, phone, pager, cisco, email, professional } = user;
              const { role = '', department = '' } = professional || {};
              const professionalLabel = [roleLang[role], departmentLang[department]]
                .filter(v => v !== undefined)
                .join(', ');

              const shareURL = ROUTES.CONTACTS_PREVIEW.replace(':id', id);
              const disabled = disabledIds.includes(id);

              return (
                <div className={styles.tableRow} key={id} onClick={() => onClickRow(id)}>
                  <div className={styles.columnValue}>
                    <p className={classNames([styles.nameValue, styles.link])} onClick={onClickRow}>
                      {formatFullName({ firstName, lastName })}
                    </p>
                    {professionalLabel.length ? (
                      <p className={styles.professionalValue}>{professionalLabel}</p>
                    ) : null}
                  </div>
                  <p className={styles.columnValue}>{phone || '-'}</p>
                  <p className={styles.columnValue}>{pager || '-'}</p>
                  <p className={styles.columnValue}>{cisco || '-'}</p>
                  <p className={styles.columnValue}>
                    {email ? (
                      <a href={`mailto:${email}`} className={styles.link}>
                        {email}
                      </a>
                    ) : (
                      '-'
                    )}
                  </p>
                  <div className={styles.actionColumn}>
                    <Tooltip title={lang.SHARE}>
                      <Button type="link" className={styles.buttonIcon}>
                        <ShareBoxComponent
                          iconClassName={classNames([styles.icon, styles.shareIcon])}
                          path={shareURL}
                        />
                      </Button>
                    </Tooltip>
                    {canEdit && (
                      <Tooltip title={disabled ? lang.CANNOT_REMOVE_MANAGER : lang.REMOVE}>
                        <Button
                          type="link"
                          disabled={disabled}
                          onClick={e => {
                            e.stopPropagation();
                            handleRemoveUser(id);
                          }}
                          className={styles.buttonIcon}
                        >
                          <FontAwesomeIcon
                            icon={removingId === id ? faSpinner : faTrashAlt}
                            spin={removingId === id}
                            className={classNames([
                              styles.icon,
                              styles.removeIcon,
                              { [styles.disabled]: disabled },
                            ])}
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <NotFound text={notFoundText} />
      )}
    </div>
  );
};

export { TeamManager };
