import React from 'react';

import Doctor from './doctor.svg';
import DoctorDocs from './doctorDocs.svg';
import LogoAsset from './logo.png';
import LogoWhiteAsset from './LogoWhite.png';
import LogoDots from './LogoDots.svg';
import ArrowIcon from './ArrowIcon';
import Search from './Search';
import BookmarkIcon from './bookmark.svg';
import MenuIcon from './MenuIcon';
import DoctorError from './doctorError.svg';
import QuestionMark from './QuestionMark';
import DownloadIcon from './DownloadIcon';
import ExclamationMark from './ExclamationMark';
import DoctorSpirit from './doctorSpirit.svg';
import DoctorStandWithLeg from './doctorStandWithLeg.svg';
import DoctorStand from './doctorStand.svg';
import InformationIcon from './InformationIcon';
import CalculatorIcon from './CalculatorIcon';
import CheckMark from './CheckMark';
import Notification from './notification.svg';
import SearchMan from './searchMan.svg';
import Cross from './cross.svg';
import GooglePlay from './googlePlay.png';
import AppStore from './appStore.png';
import PlusIcon from './PlusIcon';
import Pencil from './Pencil';
import DeleteIcon from './DeleteIcon';
import DragIcon from './DragIcon';
import FileIcon from './file.svg';
import DirectoryIcon from './Directory';
import MediaIcon from './media-icon.js';
import CommentIcon from './comment-icon.js';
import * as TagIcons from './tags';
import SelectIcon from './select.svg';
import Link from './link.svg';
import CrossIcon from './CrossIcon';
import NewsFeed from './news.svg';
import NotificationLink from './news_link.svg';
import DashboardIcon from './dashboardIcon.svg';
import ArrowFlat from './arrow-flat.svg';
import GreyArrowIcon from './grey-arrow.svg';
import ReferenceIcon from './ReferenceIcon.js';
import FilterIcon from './filter.svg';
import ItemNumberIcon from './item-number-icon.svg';
import MediaNumberIcon from './media-number-icon.svg';
import UploadIcon from './upload.svg';
import FolderAddIcon from './folder-add.svg';
import DownloadDocIcon from './download-doc.svg';
import PagerIcon from './pager-icon.js';
import AddAccountSuccess from './add-account-success.png';
import HubBackground from './hub-background.png';
import HomeIcon from './home.svg';

const LogoWhite = props => <img src={LogoWhiteAsset} alt={props.alt} {...props} />;

const Logo = props => <img src={LogoAsset} alt={props.alt} {...props} />;

export * from './menu';
export * from './resources';
export * from './items';
export * from './horizontal_icons';
export * from './entities';
export * from './logos';
export * from './panda';
export {
  Doctor,
  DoctorDocs,
  Logo,
  LogoWhite,
  LogoDots,
  Search,
  ArrowIcon,
  BookmarkIcon,
  MenuIcon,
  DoctorError,
  QuestionMark,
  DownloadIcon,
  ExclamationMark,
  DoctorSpirit,
  DoctorStandWithLeg,
  DoctorStand,
  InformationIcon,
  CalculatorIcon,
  CheckMark,
  Notification,
  SearchMan,
  Cross,
  GooglePlay,
  AppStore,
  PlusIcon,
  DeleteIcon,
  DragIcon,
  FileIcon,
  Pencil,
  DirectoryIcon,
  MediaIcon,
  CommentIcon,
  TagIcons,
  SelectIcon,
  Link,
  CrossIcon,
  NewsFeed,
  NotificationLink,
  DashboardIcon,
  ArrowFlat,
  GreyArrowIcon,
  ReferenceIcon,
  FilterIcon,
  ItemNumberIcon,
  MediaNumberIcon,
  UploadIcon,
  FolderAddIcon,
  DownloadDocIcon,
  PagerIcon,
  AddAccountSuccess,
  HubBackground,
  HomeIcon,
};
