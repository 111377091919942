import styles from '../communication-item.module.scss';
import { useSelector } from 'react-redux';
import { colorPalette, EVENTS, ROUTES } from 'shared/constants';
import { getLang } from 'shared/store/selectors/lang.selector';
import { getContactItemById } from 'shared/store/selectors/contact.selector';
import { Avatar } from 'src/components/avatar/avatar.component';
import { formatFullName, formatInitials } from 'shared/utils';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import { isToday } from 'shared/utils/date.utils';
import { Link, useLocation } from 'react-router-dom-v5-compat';
import { useState } from 'react';
import _ from 'lodash';
import { Button } from 'src/components/design-system';
import { RecipientsPreviewModal } from '../../communication-center/components/recipients-group-selector/recipients-preview-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faReply } from '@fortawesome/pro-solid-svg-icons';
import { CopyToClipboard } from 'src/components/common/copy-to-clipboard';
import { Tooltip } from 'src/components/core';
import { appURL } from 'shared/constants/APIconstants';
import analyticsService from 'src/helpers/analytics.service';
import classNames from 'classnames';

const MAX_RECIPIENTS = 3;

export function NotificationHeader({ communicationItem }) {
  const { sentBy, createdAt, recipients } = communicationItem;

  const user = useSelector(({ contacts, usersManagement }) =>
    getContactItemById({ contacts, usersManagement }, sentBy),
  );
  const initials = formatInitials(user);
  const fullName = user ? `${formatFullName(user)}, ` : '';

  const [showRecipientsModal, setShowRecipientsModal] = useState(false);

  const lang = useSelector(getLang('COMMUNICATION'));
  const { formatShortDateTime, formatShortTime } = useDateFormatter();

  const location = useLocation();
  const appURLWithoutTrailingSlash = appURL.substring(0, appURL.length - 1);
  const itemUrl = `${appURLWithoutTrailingSlash}${location.pathname}`;

  const onCopy = () => {
    analyticsService.track(EVENTS.COMMUNICATION_CENTER.NOTIFICATION_COPIED, {
      notificationId: communicationItem.id,
      itemUrl,
    });
  };

  const onReply = () => {
    analyticsService.track(EVENTS.COMMUNICATION_CENTER.CLICK_ON_REPLY, {
      notificationId: communicationItem.id,
    });
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerTop}>
          <div className={styles.userAndDate}>
            <Avatar size="small" alt={initials} />
            <h3>
              {fullName && `${lang.FROM} `}
              {
                <Link
                  className={styles.linkToUser}
                  to={ROUTES.CONTACTS_PREVIEW.replace(':id', user?.id)}
                >
                  {fullName}
                </Link>
              }
              {isToday(createdAt) ? formatShortTime(createdAt) : formatShortDateTime(createdAt)}
            </h3>
          </div>

          <div className={styles.actionsBarRight}>
            <Button
              className={styles.replyButton}
              icon={<FontAwesomeIcon icon={faReply} />}
              type="default"
              size="large"
              onClick={onReply}
              href={`mailto:${user?.email ?? ''}`}
            >
              {lang.REPLY}
            </Button>

            <CopyToClipboard text={itemUrl} onCopy={onCopy}>
              <Tooltip title={lang.COPY_LINK} mouseEnterDelay={0.3}>
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  border
                  color={colorPalette.primaryMain6}
                  className={styles.actionIcon}
                />
              </Tooltip>
            </CopyToClipboard>
          </div>
        </div>

        {recipients.length > 0 && (
          <div>
            <label className={styles.recipientsLabel}>{lang.TO} </label>
            {_.take(recipients, MAX_RECIPIENTS).map((recipient, index) => (
              <Link key={recipient.id} to={ROUTES.CONTACTS_PREVIEW.replace(':id', recipient.id)}>
                <label
                  key={recipient.id}
                  className={classNames(styles.recipientsLabel, styles.recipientName)}
                >
                  {formatFullName(recipient)}
                  {index < Math.min(MAX_RECIPIENTS - 1, recipients.length - 1) && ', '}
                </label>
              </Link>
            ))}
            {recipients.length > MAX_RECIPIENTS && (
              <>
                <label className={styles.recipientsLabel}>, {lang.AND} </label>
                <Button
                  type="link"
                  className={styles.moreRecipients}
                  onClick={() => setShowRecipientsModal(true)}
                >
                  <p>{`${recipients.length - MAX_RECIPIENTS} ${lang.MORE}`}</p>
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      <RecipientsPreviewModal
        userIds={recipients.map(({ id }) => id)}
        open={showRecipientsModal}
        closeModal={() => setShowRecipientsModal(false)}
      />
    </>
  );
}
