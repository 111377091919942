import React, { useEffect, useMemo, useState } from 'react';
import { Button, SelectInput } from '../core';
import { Divider } from 'antd';
import styles from './owner-selector.module.scss';
import { OwnerEditor } from './owner-editor';
import classNames from 'classnames';
import { Lang } from 'shared/constants/lang';
import { formatFullName } from 'shared/utils';
import ownersServiceApi from 'shared/api/owners.service.api';

const OwnerSelectorComponent = React.memo(function OwnerSelectorComponent({
  value = null,
  onChange = () => {},
  name = 'ownerId',
  placeholder = '',
  initialError = null,
  wrapperClass = null,
  inputStyle = null,
  canAddOwner = false,
  lang = Lang.OWNER_SELECTOR,
  currentOwnerId = null,
  workspaceId = null,
}) {
  const [isCreate, setIsCreate] = useState(false);
  const [ownerId, setOwnerId] = useState(value);
  const [ownersList, setOwnersList] = useState([]);
  const [error, setError] = useState(initialError);

  useEffect(() => {
    const fetchOwners = async () => {
      let res;
      if (!workspaceId) {
        res = await ownersServiceApi.getAll();
      } else {
        res = await ownersServiceApi.get({ workspaceId });
      }

      const { data, error } = res;

      if (error) {
        setError(error);
        console.warn('Fail to fetch owners');
        return;
      }

      setOwnersList(
        data.owners.map(({ id, inactive = false, isDeactivated = false, ...owner }) => ({
          id,
          value: formatFullName(owner, { withEmail: true }),
          inactive,
          isDeactivated,
        })),
      );
    };
    fetchOwners();
  }, [workspaceId]);

  // fallback in case owner was deleted but not properly replaced on all items/categories
  const isOwnerDeleted = !ownersList.find(owner => owner.id === ownerId);
  const valueToShow = !isOwnerDeleted && ownerId ? ownerId : lang.NO_OWNER;

  const options = useMemo(() => {
    return ownersList
      .filter(owner => owner.id !== currentOwnerId && !owner?.isDeactivated)
      .map(owner => ({ ...owner, disabled: owner?.inactive }));
  }, [ownersList, currentOwnerId]);

  useEffect(() => {
    if (isOwnerDeleted) {
      setOwnerId(null);
      onChange(name, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateOwner = () => {
    setIsCreate(true);
    // TODO: analytics
  };

  const closeCreateOwner = () => {
    setIsCreate(false);
    // TODO: analytics
  };

  const handleOnChange = (name, value) => {
    setOwnerId(value);
    onChange(name, value);
  };

  const dropdownRender = menu => {
    return (
      <div>
        {menu}
        {canAddOwner && (
          <>
            <Divider style={{ margin: '4px 0' }} />
            <Button size="small" onClick={openCreateOwner} className={styles.addButton}>
              {lang.ADD_NEW_OWNER}
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {!isCreate && (
        <SelectInput
          value={valueToShow}
          options={options}
          error={error}
          placeholder={placeholder}
          name={name}
          dropdownRender={dropdownRender}
          wrapperClass={classNames(styles.root, wrapperClass)}
          inputStyle={inputStyle}
          onChange={handleOnChange}
        />
      )}

      <OwnerEditor isOpen={isCreate} onFinish={closeCreateOwner} selectOwnerId={handleOnChange} />
    </>
  );
});

export { OwnerSelectorComponent };
