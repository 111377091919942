import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ROUTES } from 'shared/constants';
import styles from './search.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import classNames from 'classnames';

const SearchComponent = ({ updateSearch, term, lang }) => {
  let history = useHistory();
  let isOnSearch = useRouteMatch(ROUTES.SEARCH);
  const ref = React.useRef();

  const onFocus = () => {
    if (!isOnSearch) {
      history.push(ROUTES.SEARCH);
    }
  };

  const onSearch = React.useCallback(
    ({ target }) => {
      updateSearch(target.value);
    },
    [updateSearch],
  );

  // 2 ways binding for search input value and store term
  React.useEffect(() => {
    if (term === '' && ref.current?.value !== '') {
      ref.current.value = ''; // clear search input if the term is empty in the store
    }
  }, [term]);

  const clearSearch = () => {
    updateSearch('');
    ref.current.value = '';
  };

  return (
    <div className={classNames(['new-design-system', styles.root])}>
      <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" className={styles.icon} />
      <input
        ref={ref}
        onFocus={onFocus}
        onChange={onSearch}
        className={styles.input}
        placeholder={lang.SEARCH_PLACEHOLDER}
      />
      <FontAwesomeIcon
        className={styles.clearSearch}
        onClick={clearSearch}
        icon={faCircleXmark}
        size="xl"
      />
    </div>
  );
};

SearchComponent.defaultProps = {
  updateSearch: () => {},
};

export { SearchComponent };
