import { connect } from 'react-redux';
import { sendNotification } from 'shared/store/actions/communication-center.actions';
import { CommunicationCenterPageComponent } from './communication-center.component';
import { getSelectedWorkspaceId } from 'shared/store/selectors/workspace.selector';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';
import { uploadFileToGCP } from 'shared/store/actions/knowledge.items.actions';

const mapStateToProps = ({ lang, workspace, auth, knowledge, admission, lab, organization }) => ({
  lang: lang.COMMUNICATION_CENTER,
  currentWorkspaceId: getSelectedWorkspaceId({ workspace }),
  userId: auth.user.id,
  knowledge,
  admission,
  lab,
  organizationStartWorkingHours: organization?.workingHoursStartTime || 7,
});

const CommunicationCenterPageWrapper = props => {
  return (
    <RotationOptionsProvider>
      <CommunicationCenterPageComponent {...props} />
    </RotationOptionsProvider>
  );
};

export const CommunicationCenterPage = connect(mapStateToProps, {
  sendNotification,
  uploadFile: uploadFileToGCP,
})(CommunicationCenterPageWrapper);
