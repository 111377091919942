import styles from './communication-item.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { colorPalette, EVENTS, ROUTES } from 'shared/constants';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Divider, LinkButton, ScreenTitle } from 'src/components/design-system';
import { NotificationHeader } from './components';
import { NotFound } from 'src/components/common';
import { ResourceList } from 'src/components/core/select-content/resource-list.component';
import { useKnowledgeParser } from 'shared/utils/rotations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { Loader, Tooltip } from 'src/components/core';
import { useEffect, useState } from 'react';
import communicationServiceApi from 'shared/api/communication.service.api';
import ReactMarkdown from 'react-markdown';
import analyticsService from 'src/helpers/analytics.service';

export function CommunicationItemPreviewPage() {
  const { id } = useParams();
  const knowledge = useSelector(state => state.knowledge);
  const admission = useSelector(state => state.admission);
  const lab = useSelector(state => state.lab);
  const { parse } = useKnowledgeParser({ knowledge, admission, lab });

  const lang = useSelector(getLang('COMMUNICATION'));
  const [communicationItem, setCommunicationItem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItem = async () => {
      setLoading(true);
      const { error, data } = await communicationServiceApi.getMessageById({ id });
      setLoading(false);
      if (error) {
        return;
      }

      const { message } = data;
      if (message) {
        analyticsService.track(EVENTS.COMMUNICATION_CENTER.NOTIFICATION_OPEN, {
          notificationId: id,
        });
      }
      setCommunicationItem(message);
    };
    fetchItem();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (!communicationItem) {
    return <NotFound />;
  }

  return (
    <div className={classNames('new-design-system', styles.root)}>
      <LinkButton to={ROUTES.HOME} className={styles.goBack}>
        <Tooltip title={lang.GO_BACK} mouseEnterDelay={0.3}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={styles.actionIcon}
            border
            color={colorPalette.primaryMain6}
          />
        </Tooltip>
      </LinkButton>

      <ScreenTitle title={communicationItem.title} />

      <div className={styles.card}>
        <NotificationHeader communicationItem={communicationItem} />

        <Divider dashed />

        <div className={styles.body}>
          <ReactMarkdown className={styles.content}>{communicationItem.content}</ReactMarkdown>

          <div className={styles.attachments}>
            <ResourceList
              resources={communicationItem.attachments.map(parse)}
              backgroundColor={colorPalette.neutral2}
              showNotFoundPanda={false}
              wrapperClass={styles.resourceItem}
              openInNewTab
              analyticsTriggers={{
                open: item =>
                  analyticsService.track(EVENTS.COMMUNICATION_CENTER.ATTACHMENT_OPEN, {
                    item,
                    notificationId: id,
                  }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
