import { connect } from 'react-redux';
import { KnowledgePageComponent } from './knowledge.page.component';
import { getKnowledgeContent } from 'shared/store/actions/knowledge.actions';
import {
  getCategoryById,
  getCategoriesById,
  getRoot,
} from 'shared/store/selectors/knowledge.selector';
import { getSelectedWorkspace } from 'shared/store/selectors/workspace.selector';

const mapStateToProps = ({ knowledge, workspace }, { match }) => {
  let { id } = match.params;

  let isRoot = !id;

  let category = isRoot ? getRoot(knowledge) : getCategoryById(knowledge, { id });

  let children = getCategoriesById(knowledge, category.id);

  const workspaceName = getSelectedWorkspace({ workspace })?.name;

  return {
    id: category.id,
    ...category,
    title: isRoot ? workspaceName : category.title,
    children,
    isRoot,
    lastUpdated: knowledge.lastUpdated,
    isReady: knowledge.isReady,
    workspaceName,
  };
};

export const KnowledgePage = connect(mapStateToProps, { getKnowledgeContent })(
  KnowledgePageComponent,
);
