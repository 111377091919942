import classNames from 'classnames';
import styles from './buttons.module.scss';
import { Link } from 'react-router-dom-v5-compat';

/**
 * Link button component
 * @param {import('react-router-dom-v5-compat').LinkProps} param0
 * @returns
 */
export function LinkButton({ children, className, ...props }) {
  return (
    <Link className={classNames(styles.linkButton, className)} {...props}>
      {children}
    </Link>
  );
}
