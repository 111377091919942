import _ from 'lodash';
import { getParentIdByPath } from '../../utils';
import workspaceService from '../../services/workspace.service';
import { CATEGORY_TYPES } from '../../constants';
import { hasPermissionsToEditItem } from './iam.selector';
import { getUser } from './user.selector';

export const getByIdCategory = (category, idToSearch) => {
  if (category.children && category.children.length !== 0) {
    let result;
    category.children.forEach(item => {
      if (result) return false;
      let res = getByIdCategory(item, idToSearch);

      if (res) result = res;
    });

    return result;
  }

  if (category.knowledge_item_medias && category.knowledge_item_medias !== 0) {
    return category.knowledge_item_medias.find(({ id }) => idToSearch === id);
  }
};

/**
 * Check if the category is a section
 * if path contains root, sections are items that have an odd number of path parts
 * if path does not contain root, sections are items that have an even number of path partss
 * @param {*} pathParts
 * @returns boolean
 */
export const isSection = (pathParts = []) => {
  const hasRoot = pathParts?.includes(new RegExp(/ROOT/gi));
  return hasRoot ? pathParts?.length % 2 !== 0 : pathParts?.length % 2 === 0;
};

export const getLabRoot = ({ categories }, initialWorkspaceId = null) => {
  //support orgs with and without workspaces
  if (!workspaceService.hasWorkspaces()) {
    if (!categories) return {};
    return categories.find(category => category.path?.toLowerCase() === 'root');
  }

  let workspaceId = initialWorkspaceId;

  if (!workspaceId) {
    workspaceId = workspaceService.getSelectedWorkspaceId();
  }

  if (!categories || !workspaceId) return {};

  return (
    categories.find(
      category => category.path?.toLowerCase() === 'root' && category.workspaceId === workspaceId,
    ) ?? {}
  );
};

export const getCategoriesByPath = ({ categories, categoriesById }, path) => {
  if (_.isNull(categories)) return null;

  let parentId = getParentIdByPath(path);

  return getLabCategoriesById({ categoriesById }, parentId);
};

export const getLabCategoriesById = ({ categoriesById }, id) => {
  if (_.isNull(categoriesById)) return null;

  if (!categoriesById[id]) return null;

  return categoriesById[id].subcategories.map(i =>
    getLabCategoryById({ categoriesById }, { id: i }),
  );
};

export const getLabCategoryById = ({ categoriesById }, { id }) => {
  return categoriesById ? categoriesById[id] || {} : {};
};

export const getItemById = ({ itemsById }, { id }) => {
  return itemsById[id] || null;
};

export const generateBreadcrumbs = (
  { categoriesById, categories },
  { pathParts = [], initialWorkspaceId = null },
) => {
  const rootId = getLabRoot({ categories }, initialWorkspaceId)?.id;

  return pathParts
    ? pathParts
        .filter(part => part.toLowerCase() !== 'root' && part !== rootId)
        .map(id => categoriesById[id])
    : [];
};

export const getItemsByCategoryId = ({ itemsById, categoriesById }, { id }) => {
  let { values = [] } = categoriesById[id] || {};

  return values.map(id => itemsById[id] || null).filter(i => !!i);
};

export const getLabParentsByItemId = ({ categories }, { id }) => {
  return categories?.filter(i => i.type === 'values' && i.values.includes(id)) || [];
};

/**
 * Check if the user is the owner of the category and all of its children
 *
 * @param {ReduxState} state
 * @param {{ id: ObjectId }} { id } Lab entity id
 * @return {Boolean} if the user is the owner of the category and all of its children
 */
export function isCompendiumOwner(state, { id }) {
  if (!id) return false;

  const hasPermissions = hasPermissionsToEditItem(state);

  // Check if the user has permission to bypass the owner restriction
  if (hasPermissions) return true;

  // Get user id
  const { id: userId } = getUser(state);

  return _checkLabChildrenOwnership(state, id, userId);
}

/**
 * Check if the user is the owner of the category and all of its children
 *
 * @param {ReduxState} state
 * @param {ObjectId} id - category id
 * @param {ObjectId} userId - user id
 * @return {Boolean} if the user is the owner of the category and all of its children
 */
function _checkLabChildrenOwnership(state, id, userId) {
  // get category by id
  const item = getLabCategoryById(state.lab, { id });

  // check if the category is owned by the user
  if (!item.ownerIds?.includes(userId)) {
    return false;
  }

  if (item.type === CATEGORY_TYPES.CATEGORY) {
    // recursively check if all of the subcategories are owned by the user
    return item.subcategories.every(subcategoryId => {
      return _checkLabChildrenOwnership(state, subcategoryId, userId);
    });
  }

  return item.values?.every(valId => {
    const value = getItemById(state.lab, { id: valId });
    return value?.ownerIds?.includes(userId);
  });
}

export const isValueOwner = ({ auth }, item) => {
  return item.ownerIds?.includes(auth.user.id);
};
