import React, { useState } from 'react';
import styles from './login-bubble.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { isNormalUser } from 'shared/store/selectors/user.selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { Modal } from 'antd';
import { OTPConfirm } from '../../application/switchOrganization/otp-confirmation-modal.component';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';
import userService from 'shared/services/user.service';

export function LoginBubble() {
  const lang = useSelector(getLang('ONBOARDING'));
  const isUser = useSelector(isNormalUser);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [closeBubble, setCloseBubble] = useState(false);

  if (isUser) {
    return null;
  }

  const onRootClick = () => {
    analyticsService.track(EVENTS.AUTH.LOGIN_BUBBLE_CLICKED);
  };

  const onOpenLogin = () => {
    setOtpModalOpen(true);

    analyticsService.track(EVENTS.AUTH.LOGIN_BUBBLE_LOGIN_FLOW_OPENED);
  };

  const onCloseLogin = () => {
    setOtpModalOpen(false);

    analyticsService.track(EVENTS.AUTH.LOGIN_BUBBLE_LOGIN_FLOW_CLOSED);
  };

  const onSingIn = async email => {
    const res = await userService.signin({ email, navigateToOtp: false });
    if (res?.error) {
      analyticsService.track(EVENTS.AUTH.LOGIN_BUBBLE_LOGIN_FAILED, { error: res.error, email });
    }
    return res;
  };

  const onVerifyCode = async ({ userId, code, email }) => {
    const res = await userService.verifyCode({ code, userId });
    if (res?.error) {
      analyticsService.track(EVENTS.AUTH.LOGIN_BUBBLE_LOGIN_FAILED, {
        error: res.error,
        userId,
        code,
        email,
      });
    }
    return res;
  };

  const onFinished = async () => {
    analyticsService.track(EVENTS.AUTH.LOGIN_BUBBLE_LOGIN_SUCCESS);
    setOtpModalOpen(false);
  };

  if (closeBubble) {
    return (
      <div className={styles.rootClose} onClick={() => setCloseBubble(false)}>
        <FontAwesomeIcon icon={faRightToBracket} />
      </div>
    );
  }
  return (
    <>
      <Modal
        open={otpModalOpen}
        onCancel={onCloseLogin}
        width={700}
        footer={null}
        centered
        maskClosable={false}
        destroyOnClose
      >
        <OTPConfirm
          showSuccess={false}
          lang={lang}
          next={onFinished}
          submitEmailCallback={onSingIn}
          verifyCodeCallback={onVerifyCode}
        />
      </Modal>
      <div className={styles.root} onClick={onRootClick}>
        <div className={styles.fullExperienceWrapper}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className={styles.closeIcon}
            onClick={() => setCloseBubble(true)}
          />
          <p>{lang.FULL_EXPERIENCE}</p>
        </div>
        <button onClick={onOpenLogin} className={styles.loginButtonWrapper}>
          <div className={styles.loginInnerDiv}>
            <FontAwesomeIcon icon={faRightToBracket} />
            {lang.LOGIN}
          </div>
        </button>
      </div>
    </>
  );
}
