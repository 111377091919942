import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';
import { ROUTES } from 'shared/constants';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';
import { formatFullName } from 'shared/utils';
import styles from './owners.module.scss';
import { useVettedOrProvidedLang } from 'shared/hooks/lang.hooks';
import { getLang } from 'shared/store/selectors/lang.selector';

export function Owners({
  ownerIds = [],
  initialOwners = null,
  navigateToOwnerProfile = true,
  title = '',
  titleClassName = '',
  onNavigate = () => {},
  disabledClassName = '',
}) {
  let owners = useSelector(getOwnersByIDs(ownerIds));
  const organizationId = useSelector(({ organization }) => organization.id);
  const validatedByLang = useVettedOrProvidedLang(title);
  const userLang = useSelector(getLang('USER_PROFILE'));

  if (initialOwners) {
    owners = initialOwners.filter(Boolean);
  }

  return (
    <div className={styles.root}>
      {validatedByLang ? <span className={titleClassName}>{validatedByLang.trim()}</span> : null}
      {owners.map(owner => {
        return navigateToOwnerProfile && organizationId === owner.organizationId ? (
          <Link
            key={owner.id}
            to={ROUTES.CONTACTS_PREVIEW.replace(':id', owner.id)}
            onClick={onNavigate}
            className={disabledClassName}
          >
            {formatFullName(owner, {
              labelInParenthesis: owner?.isDeactivated ? userLang.DEACTIVATED : null,
            })}
          </Link>
        ) : (
          <a
            key={owner.id}
            href={`mailto:${owner.email || ''}`}
            target="_blank"
            rel="noreferrer"
            className={disabledClassName}
          >
            {formatFullName(owner, {
              labelInParenthesis: owner?.isDeactivated ? userLang.DEACTIVATED : null,
            })}
          </a>
        );
      })}
    </div>
  );
}
