export const DEVICE_FLAGS = {
  ONBOARDING_FINISHED: 'ONBOARDING_FINISHED',
  IS_FIRST_TIME: 'IS_FIRST_TIME',
  LAST_OPEN: 'LAST_OPEN',
  CURRENT_LOGIN: 'CURRENT_LOGIN',
  UPDATE_CHECKS_COUNT: 'UPDATE_CHECKS_COUNT',
  LANGUAGE: 'LANGUAGE',
  VIEW_PREFERENCES: 'VIEW_PREFERENCES',
  LAST_NOTIFICATION_PROMOTION_CHECK: 'LAST_NOTIFICATION_PROMOTION_CHECK',
};
