import { createContext, useContext, useEffect, useState } from 'react';
import userServiceApi from 'shared/api/user.service.api';

const TotalRecipientsContext = createContext();

export const useTotalRecipients = () => {
  return useContext(TotalRecipientsContext);
};

const getTotalRecipients = async ({ groups, individuals }) => {
  const { data, error } = await userServiceApi.getUserGroups({ groups, individuals });
  if (error) {
    return [];
  }
  return data.users;
};

export function TotalRecipientsProvider({ individuals, groups, children }) {
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTotalRecipients = async () => {
      setLoading(true);
      const res = await getTotalRecipients({ groups, individuals });
      setRecipients(res);
      setLoading(false);
    };

    fetchTotalRecipients();
  }, [individuals, groups]);

  const value = {
    recipients,
    loading,
  };

  return (
    <TotalRecipientsContext.Provider value={value}>{children}</TotalRecipientsContext.Provider>
  );
}
