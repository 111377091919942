import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'src/components/core';

export function LearnMore({ link = undefined, text = 'Learn more', size = 'lg' }) {
  return (
    <Tooltip title={text} mouseEnterDelay={0.2}>
      <a href={link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faCircleInfo} size={size} />
      </a>
    </Tooltip>
  );
}
