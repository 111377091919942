import { connect } from 'react-redux';
import { AdmissionPageComponent } from './admission.page.component';
import {
  getAdmissionRoot,
  getAdmissionCategoryById,
  getAdmissionCategoriesById,
} from 'shared/store/selectors/admission.selector';
import { getSelectedWorkspace } from 'shared/store/selectors/workspace.selector';

const mapStateToProps = ({ admission, workspace }, { match }) => {
  let category;
  let { id } = match.params;
  let isRoot = false;

  if (id) {
    category = id ? getAdmissionCategoryById(admission, { id }) : {};
  } else {
    category = getAdmissionRoot(admission);
    id = category?.id;
    isRoot = true;
  }

  const workspaceName = getSelectedWorkspace({ workspace })?.name;

  return {
    id,
    category: {
      ...category,
      title: isRoot ? workspaceName : category.title,
    },
    showBreadcrumbs: !isRoot,
    children: getAdmissionCategoriesById(admission, id) || [],
    lastUpdated: admission.lastUpdated,
    isReady: admission.isReady,
  };
};

export const AdmissionPage = connect(mapStateToProps, {})(AdmissionPageComponent);
