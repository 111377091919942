import API from './API';
import { GET, POST, PUT } from '../utils/Executer';
import { ORGANIZATION_SERVICE_URL as servicePath } from '../constants/APIconstants';

class OrganizationServiceAPI {
  async me() {
    return await API.client.execute(GET, `${servicePath}me`);
  }

  async available() {
    return await API.client.execute(GET, `${servicePath}available`);
  }

  async getKeywords() {
    return await API.client.execute(GET, `${servicePath}keywords`);
  }

  async generateMagicLink({ allowDomains, allowIpRanges, organizationId, defaultWorkspaceId }) {
    return await API.client.execute(POST, `${servicePath}${organizationId}/magic-link`, {
      allowDomains,
      allowIpRanges,
      defaultWorkspaceId,
    });
  }

  async getMagicLinks({ organizationId }) {
    return await API.client.execute(GET, `${servicePath}${organizationId}/magic-link`);
  }

  async updateMagicLink({
    allowDomains,
    allowIpRanges,
    organizationId,
    magicLinkId,
    defaultWorkspaceId,
  }) {
    return await API.client.execute(
      PUT,
      `${servicePath}${organizationId}/magic-link/${magicLinkId}`,
      {
        allowDomains,
        allowIpRanges,
        defaultWorkspaceId,
      },
    );
  }
}

export default new OrganizationServiceAPI();
