import API from './API';
import { DELETE, GET, POST } from '../utils/Executer';
import { COMMUNICATION_SERVICE_URL as servicePath } from '../constants/APIconstants';
import { NEWS_FEED_DASHBOARD_PARAMS } from '../constants/communication.constants';

class CommunicationServiceAPI {
  async sendNotification({ data }) {
    return await API.client.execute(POST, servicePath, data);
  }
  async getNewsFeedItems() {
    return await API.client.execute(
      GET,
      `${servicePath}?pageSize=${NEWS_FEED_DASHBOARD_PARAMS.pageSize}`,
    );
  }

  /**
   * Delete communication item
   * @param {*} { id }
   * @return {*}
   * @memberof CommunicationServiceAPI
   */
  async deleteItem({ id }) {
    return await API.client.execute(DELETE, `${servicePath}${id}`);
  }

  /**
   * Report issue
   * @param {*} { data }
   * @return {*}
   * @memberof CommunicationServiceAPI
   */
  async reportIssue({ data }) {
    return await API.client.execute(POST, `${servicePath}report-issue`, data);
  }

  /**
   * Give feedback
   * @param {*} { data }
   * @return {*}
   * @memberof CommunicationServiceAPI
   */
  async giveFeedback({ data }) {
    return await API.client.execute(POST, `${servicePath}give-feedback`, data);
  }

  /**
   * Request access
   * @param {{
   *  data: {
   *    timestamp: string,
   *    itemId: string,
   *    entity: string,
   * }
   * }} { data }
   * @return {Promise<unknown>}
   * @memberof CommunicationServiceAPI
   */
  async requestAccess({ data }) {
    return await API.client.execute(POST, `${servicePath}request-access`, data);
  }

  /**
   * Get communication item by id
   * @param {{id: string}} { id }
   */
  async getMessageById({ id }) {
    return await API.client.execute(GET, `${servicePath}${id}`);
  }
}

export default new CommunicationServiceAPI();
