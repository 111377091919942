import React from 'react';
import { Switch, useHistory } from 'react-router';
import { AuthRoute } from '../router/AuthRoute';
import { useSelector } from 'react-redux';
import {
  ROUTES,
  ACCOUNT_ADMIN_PERMISSIONS_GROUP,
  COLLABORATOR_PERMISSIONS_GROUP,
  ROOT_PERMISSIONS_GROUP,
} from 'shared/constants';
import { PrivateRoute } from '../router/PrivateRoute';
import {
  SignInPage,
  VerificationPage,
  NotAllowedPage,
  OauthCallback,
} from '../../pages/authentication';
import { setNavigate } from 'shared/services/navigation.service';
import {
  FavoritesPage,
  KnowledgePage,
  SearchPage,
  LabsPage,
  SwitchOrganizationPage,
  UserManagementPage,
  OwnersManagementPage,
  AdmissionPage,
  CommunicationCenterPage,
  DashboardPage,
  KnowledgeContentPage,
  UserProfilePage,
  SuggestContentPage,
  HubComingSoonPage,
  KnowledgeHubPage,
  KnowledgeHubContentPage,
  CommunicationItemPreviewPage,
} from '../../pages/application';
import ScrollRestoration from '../core/scroll-restoration';
import { ContactsPage } from '../../pages/application/contacts';
import {
  isContactsFeatureEnabled,
  isRotationsEnabled,
} from 'shared/store/selectors/features.selector';
import { Route } from '../router/Route.component';
import { AppLayout } from '../app-layout';
import { RotationsPage } from 'src/pages/application/rotations/rotations.page.component';
import {
  hasPermissionsToAccessKnowledgeHub,
  hasPermissionsToSeeSettings,
  hasPermissionsToShareToKnowledgeHub,
} from 'shared/store/selectors/iam.selector';
import { SettingsPage } from 'src/pages/application/settings';
import { KnowledgeHubSearchPage } from 'src/pages/application/knowledge-hub/knowledge-hub-search.page';
import { ContactPreviewPage } from 'src/pages/application/contacts/contact-preview.page';
import { ContactEditPage } from 'src/pages/application/contacts/contact-edit.page';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';

import {
  WorkspaceManagementPage,
  MagicLinkManagementPage,
} from 'src/pages/application/settings/components';
import { AccessRestricted } from '../common/access-restricted';
import { notification } from 'antd';

const Router = React.memo(() => {
  const history = useHistory();

  const isContactsEnabled = useSelector(isContactsFeatureEnabled);
  const isRotationsEnabledState = useSelector(isRotationsEnabled);
  const isKnowledgeHubVisible = useSelector(hasPermissionsToAccessKnowledgeHub);
  const isAbleToShareToKnowledgeHub = useSelector(hasPermissionsToShareToKnowledgeHub);
  const isAllowedToEditContacts = useSelector(isAccountAdmin);

  const isKnowledgeHubComingSoonVisible = !isKnowledgeHubVisible && isAbleToShareToKnowledgeHub;
  const canAccessSettings = useSelector(hasPermissionsToSeeSettings);
  const [dashboardApi, dashboardContextHolder] = notification.useNotification();

  React.useEffect(() => {
    setNavigate(history);
  }, [history]);

  return (
    <Switch>
      <AuthRoute exact path={ROUTES.WELCOME} component={SignInPage} />
      <AuthRoute exact path={ROUTES.OTP_VERIFICATION} component={VerificationPage} />

      <AuthRoute exact path={ROUTES.NOT_ALLOWED} component={NotAllowedPage} />

      {/* OAuth callback for authentication process */}
      <Route exact path={ROUTES.OAUTH_CALLBACK} component={OauthCallback} />

      <PrivateRoute path={ROUTES.ROOT}>
        {dashboardContextHolder}
        <ScrollRestoration />
        <AppLayout>
          <PrivateRoute exact path={[ROUTES.ROOT, ROUTES.HOME]} component={DashboardPage} />
          <PrivateRoute
            exact
            path={ROUTES.SUGGEST_CONTENT}
            component={() => <SuggestContentPage api={dashboardApi} />}
          />
          <PrivateRoute exact path={ROUTES.SEARCH} component={SearchPage} />
          <PrivateRoute exact path={ROUTES.FAVORITES} component={FavoritesPage} />
          {isContactsEnabled && (
            <>
              <PrivateRoute exact path={ROUTES.CONTACTS} component={ContactsPage} />
              <PrivateRoute exact path={ROUTES.CONTACTS_PREVIEW} component={ContactPreviewPage} />
              {isAllowedToEditContacts && (
                <PrivateRoute exact path={ROUTES.CONTACTS_EDIT} component={ContactEditPage} />
              )}
            </>
          )}
          <PrivateRoute exact path={ROUTES.LABS} component={LabsPage} />
          {canAccessSettings && (
            <>
              <PrivateRoute exact path={ROUTES.SETTINGS} component={SettingsPage} />
              <PrivateRoute
                exact
                path={ROUTES.SETTINGS_WORKSPACES}
                component={WorkspaceManagementPage}
              />
            </>
          )}
          <PrivateRoute
            exact
            path={ROUTES.SETTINGS_MAGIC_LINK}
            permissions={ROOT_PERMISSIONS_GROUP}
            component={MagicLinkManagementPage}
          />
          <PrivateRoute exact path={ROUTES.LABS_CATEGORIES} component={LabsPage} />
          <PrivateRoute exact path={ROUTES.ADMISSION} component={AdmissionPage} />
          <PrivateRoute exact path={ROUTES.ADMISSION_CATEGORIES} component={AdmissionPage} />
          <PrivateRoute exact path={ROUTES.USER_PROFILE} component={UserProfilePage} />
          <PrivateRoute
            exact
            path={ROUTES.SWITCH_ORGANIZATION}
            component={SwitchOrganizationPage}
          />
          <PrivateRoute
            exact
            permissions={ACCOUNT_ADMIN_PERMISSIONS_GROUP}
            path={ROUTES.USERS_MANAGEMENT}
            component={UserManagementPage}
          />
          <PrivateRoute
            exact
            permissions={ACCOUNT_ADMIN_PERMISSIONS_GROUP}
            path={ROUTES.OWNERS_MANAGEMENT}
            component={OwnersManagementPage}
          />
          <PrivateRoute
            exact
            permissions={COLLABORATOR_PERMISSIONS_GROUP}
            path={ROUTES.COMMUNICATION_CENTER}
            component={CommunicationCenterPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.COMMUNICATION_ITEM}
            component={CommunicationItemPreviewPage}
          />

          {isRotationsEnabledState && (
            <PrivateRoute exact path={ROUTES.ROTATIONS} component={RotationsPage} />
          )}

          {/* Knowledge routes */}
          <PrivateRoute exact path={ROUTES.CONTENT_ITEM} component={KnowledgePage} />
          <PrivateRoute exact path={ROUTES.KNOWLEDGE} component={KnowledgePage} />
          <PrivateRoute exact path={ROUTES.KNOWLEDGE_ITEMS} component={KnowledgeContentPage} />

          {/* Knowledge Hub routes */}
          {isKnowledgeHubComingSoonVisible && (
            <PrivateRoute path={ROUTES.KNOWLEDGE_HUB} component={HubComingSoonPage} />
          )}
          {isKnowledgeHubVisible && (
            <>
              <PrivateRoute exact path={ROUTES.KNOWLEDGE_HUB} component={KnowledgeHubPage} />
              <PrivateRoute
                exact
                path={ROUTES.KNOWLEDGE_HUB_ITEMS}
                component={KnowledgeHubContentPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.KNOWLEDGE_HUB_SEARCH}
                component={KnowledgeHubSearchPage}
              />
            </>
          )}
          <PrivateRoute exact path={ROUTES.RESTRICTED} component={AccessRestricted} />
        </AppLayout>
      </PrivateRoute>
    </Switch>
  );
});

export { Router };
