import API from './API';
import { DELETE, GET, PATCH, POST, PUT } from '../utils/Executer';
import { WORKSPACE_SERVICE_URL as servicePath } from '../constants/APIconstants';

class WorkspaceServiceAPI {
  async getWorkspaces() {
    return await API.client.execute(GET, `${servicePath}`);
  }

  async selectWorkspace({ workspaceId }) {
    return await API.client.execute(PUT, `${servicePath}select`, { workspaceId });
  }

  async createWorkspace({ name, organizationId, operational }) {
    return await API.client.execute(POST, `${servicePath}`, { name, organizationId, operational });
  }

  async updateWorkspace({ workspaceId, ...data }) {
    return await API.client.execute(PUT, `${servicePath}${workspaceId}`, data);
  }

  async restrictWorkspace({ workspaceId, restricted }) {
    return await API.client.execute(PATCH, `${servicePath}${workspaceId}/restrict`, { restricted });
  }

  async getWorkspaceUsers(workspaceId) {
    return await API.client.execute(GET, `${servicePath}${workspaceId}/users`);
  }

  async addUsersToWorkspace({ workspaceId, userIds }) {
    return await API.client.execute(PATCH, `${servicePath}${workspaceId}/users`, { userIds });
  }

  async removeUserFromWorkspace({ workspaceId, userId, altOwnerId }) {
    return await API.client.execute(PATCH, `${servicePath}${workspaceId}/users/${userId}`, {
      altOwnerId,
    });
  }
  async manageRestrictedWorkspacesAccess({ userIds, workspaceIds }) {
    return await API.client.execute(PATCH, `${servicePath}manage-access`, {
      userIds,
      workspaceIds,
    });
  }

  async deleteWorkspace(workspaceId) {
    return await API.client.execute(DELETE, `${servicePath}${workspaceId}`);
  }
}

export default new WorkspaceServiceAPI();
