import React from 'react';
import styles from './news-feed-item.module.scss';
import { isToday } from 'shared/utils/date.utils';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import { Link } from 'react-router-dom-v5-compat';
import { colorPalette, COMMUNICATION_CENTER_NAVIGATION_SOURCE, EVENTS, ROUTES } from 'shared/constants';
import { Avatar } from 'src/components/avatar/avatar.component';
import { formatFullName, formatInitials } from 'shared/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclipVertical } from '@fortawesome/pro-regular-svg-icons';
import analyticsService from 'src/helpers/analytics.service';

export function NewsFeedItemComponent({
  id,
  title,
  createdAt,
  user,
  attachmentsCount,
  source = COMMUNICATION_CENTER_NAVIGATION_SOURCE.DASHBOARD_WIDGET,
}) {
  const { formatShortDateTime, formatShortTime } = useDateFormatter();
  const initials = formatInitials(user);
  const fullName = user ? `${formatFullName(user)}, ` : '';

  const onNavigateToNotification = () => {
    analyticsService.track(EVENTS.COMMUNICATION_CENTER.NOTIFICATION_CLICKED, {
      notificationId: id,
      source,
    });
  };

  return (
    <Link to={ROUTES.COMMUNICATION_ITEM.replace(':id', id)} onClick={onNavigateToNotification}>
      <div className={styles.root}>
        <div className={styles.rightSide}>
          <Avatar alt={initials} size="small" />
          <div className={styles.body}>
            <p className={styles.title}>{title}</p>
            <label className={styles.date}>
              {fullName}
              {isToday(createdAt) ? formatShortTime(createdAt) : formatShortDateTime(createdAt)}
            </label>
          </div>
        </div>
        {attachmentsCount > 0 && (
          <div className={styles.attachmentsCount}>
            <label>{attachmentsCount}</label>
            <FontAwesomeIcon icon={faPaperclipVertical} color={colorPalette.neutral6} />
          </div>
        )}
      </div>
    </Link>
  );
}
