import { connect } from 'react-redux';
import { generateChecklistReport, resetChecklist } from 'shared/store/actions/admission.actions';
import { AdmissionGenerateReportComponent } from './admission-generate-report.component';
import { getUser } from 'shared/store/selectors/user.selector';
import { getUsers } from 'shared/store/selectors/contact.selector';

const mapStateToProps = ({ lang, auth, usersManagement }) => ({
  lang: lang.ADMISSION_GENERATE_REPORT,
  user: getUser({ auth }),
  users: getUsers({ usersManagement }),
});

const mapDispatchToProps = {
  generateChecklistReport,
  resetChecklist,
};

export const AdmissionGenerateReport = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionGenerateReportComponent);
