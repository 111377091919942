import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styles from './workspace-restrictions.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { hasOnePublicWorkspace } from 'shared/store/selectors/workspace.selector';
import { notification, Popconfirm, Switch } from 'antd';
import { useWorkspacesContext } from '../../hooks/use-workspaces-context';
import classNames from 'classnames';
import { Divider } from 'src/components/design-system';
import { restrictWorkspace } from 'shared/store/actions/workspace.actions';
import { formatFullName } from 'shared/utils';
import { getContacts } from 'shared/store/selectors/contact.selector';
import workspaceServiceApi from 'shared/api/workspace.service.api';
import { TeamManager } from 'src/components/team/team-manager.component';
import { COLLABORATOR_PERMISSIONS_GROUP, EVENTS, ROUTES } from 'shared/constants';
import analyticsService from 'src/helpers/analytics.service';
import { Loader } from 'src/components/core';
import { TransferOwner } from 'src/pages/application/users-management/transfer-owner';
import { getOwners } from 'shared/store/actions/owners.actions';
import { LearnMore } from 'src/components/common/learn-more';

export function WorkspaceRestrictions() {
  const [open, setOpen] = useState(false);
  const [existingUsers, setExistingUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { workspace, onChangeWorkspace } = useWorkspacesContext();
  const lang = useSelector(getLang('SETTINGS'));
  const teamLang = useSelector(getLang('TEAM_MANAGER'));
  const organization = useSelector(state => state.organization);
  const canRestrict = useSelector(state =>
    hasOnePublicWorkspace({ workspace: state.workspace, selectedId: workspace.id }),
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification({ placement: 'bottomRight' });
  const showPopconfirm = () => setOpen(true);
  const userList = useSelector(getContacts);
  const [transferOwnership, setTransferOwnership] = useState(null);

  const onTeamMemberClicked = id => {
    analyticsService.track(EVENTS.WORKSPACE.OPEN_USER_DETAILS, {
      workspaceId: workspace.id,
      memberId: id,
    });

    const url = ROUTES.CONTACTS_PREVIEW.replace(':id', id);
    history.push(url);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const users = await workspaceServiceApi.getWorkspaceUsers(workspace.id);
      if (users.error) {
        api.error({
          message: users.error,
        });
        return;
      }
      setExistingUsers(users.data);
      setLoading(false);
    };
    fetchUsers();
  }, [workspace.id, api]);

  const userOptions = useMemo(
    () =>
      userList
        .filter(({ isUser = false }) => isUser)
        .filter(({ id }) => !existingUsers.some(u => u.id === id))
        .map(contact => ({
          label: formatFullName(contact, { withEmail: true }),
          value: contact.id,
        })),
    [userList, existingUsers],
  );

  const postUpdate = updatedWorkspace => {
    if (updatedWorkspace.error) {
      api.error({
        message: updatedWorkspace.error,
      });
    } else {
      api.success({
        message: lang.WORKSPACE_UPDATED,
      });
    }
    onChangeWorkspace(updatedWorkspace);
  };

  const handleOk = async () => {
    const restrictedWorkspace = await dispatch(
      restrictWorkspace({ workspaceId: workspace.id, restricted: !workspace.restricted }),
    );
    postUpdate(restrictedWorkspace);
  };

  const handleCancel = () => setOpen(false);

  const handleAddUsers = async userIds => {
    try {
      await workspaceServiceApi.addUsersToWorkspace({
        workspaceId: workspace.id,
        userIds,
      });

      await dispatch(getOwners());

      setExistingUsers(users => [...users, ...userIds.map(id => userList.find(u => u.id === id))]);
    } catch (error) {
      api.error({ message: error.message || teamLang.ADD_FAILED });
    }
  };

  const cancelTransferOwnership = () => setTransferOwnership(null);

  const onTransferOwnership = ({ user, action, dismiss }) => {
    setTransferOwnership({
      user,
      action,
      dismiss: () => {
        dismiss();
        cancelTransferOwnership();
      },
    });
  };

  const handleTransferOwnership = async ({ altOwnerId, userId }) => {
    setLoading(true);

    const res = await workspaceServiceApi.removeUserFromWorkspace({
      workspaceId: workspace.id,
      userId,
      altOwnerId,
    });

    if (res.error) {
      api.error({ message: res.error.message || teamLang.REMOVE_FAILED });
      setLoading(false);
      return;
    }

    await dispatch(getOwners());

    setExistingUsers(users => users.filter(u => u.id !== userId));
    api.success({ message: teamLang.REMOVE_SUCCESSFUL });
    setLoading(false);
  };

  const handleRemoveUser = async userId => {
    try {
      const user = existingUsers.find(u => u.id === userId);

      // trigger transfer ownership flow
      if (COLLABORATOR_PERMISSIONS_GROUP.includes(user.permissions)) {
        onTransferOwnership({
          user,
          action: handleTransferOwnership,
          dismiss: () => {
            cancelTransferOwnership();
          },
        });

        return;
      }

      await workspaceServiceApi.removeUserFromWorkspace({
        workspaceId: workspace.id,
        userId,
      });

      await dispatch(getOwners());

      setExistingUsers(users => users.filter(u => u.id !== userId));
      api.success({ message: teamLang.REMOVE_SUCCESSFUL });
    } catch (error) {
      api.error({ message: error.message || teamLang.REMOVE_FAILED });
    }
  };

  const title = workspace?.restricted ? lang.UNRESTRICT_CONFIRMATION : lang.RESTRICT_CONFIRMATION;
  const description = workspace?.restricted
    ? lang.UNRESTRICT_DESCRIPTION.replace('{organization}', organization?.name)
    : lang.RESTRICT_DESCRIPTION;

  return (
    <div className={classNames([styles.root, !canRestrict && styles.disabled])}>
      {contextHolder}
      <div className={styles.toggle}>
        <Popconfirm
          overlayClassName={styles.popconfirm}
          title={title.replace('{workspace}', workspace?.name)}
          description={description}
          open={open}
          onConfirm={handleOk}
          okText={lang.APPROVE}
          cancelText={lang.CANCEL}
          onCancel={handleCancel}
        >
          <Switch
            checked={workspace?.restricted || false}
            size="medium"
            disabled={!canRestrict}
            onChange={showPopconfirm}
          />
        </Popconfirm>
        <p className={styles.label}>{lang.RESTRICT_WORKSPACE}</p>
        <LearnMore
          link="https://help.c8health.com/resticting-a-workspace"
          text={lang.LEARN_MORE_RESTRICTED_WORKSPACE}
        />
      </div>
      <div>
        <Divider dashed />
        {!workspace?.restricted ? (
          <p className={classNames([styles.label, styles.restrictions])}>
            {!canRestrict
              ? lang.UNABLE_TO_RESTRICT
              : lang.UNRESTRICTED_WORKSPACE.replace('{organization}', organization?.name)}
          </p>
        ) : (
          <div className={styles.team}>
            {loading ? (
              <Loader />
            ) : (
              <>
                {transferOwnership && (
                  <TransferOwner
                    onConfirm={transferOwnership.action}
                    user={transferOwnership.user}
                    onDismiss={transferOwnership.dismiss}
                    workspaceId={workspace.id}
                  />
                )}
                <TeamManager
                  disabledIds={workspace?.managers}
                  existingUsers={existingUsers}
                  availableUsers={userOptions}
                  onAddUsers={handleAddUsers}
                  onRemoveUser={handleRemoveUser}
                  skipRemoveUserToast
                  onClickRow={onTeamMemberClicked}
                  canEdit={canRestrict}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
