import { connect } from 'react-redux';
import { fetchUsers, fetchUserStats } from 'shared/store/actions/users-management.actions';
import { RotationOptionsProvider } from 'shared/hooks/profile.context';
import { RecipientsGroupSelectorComponent } from './recipients-group-selector.component';

const mapStateToProps = ({ usersManagement, contacts }) => ({
  users: usersManagement.list,
  usersStats: usersManagement.stats,
  usersLastUpdated: usersManagement.lastUpdated,
});

const mapDispatchToProps = {
  fetchUsers,
  fetchUserStats,
};

const RecipientsGroupSelectorWrapper = ({ ...props }) => (
  <RotationOptionsProvider>
    <RecipientsGroupSelectorComponent {...props} />
  </RotationOptionsProvider>
);

const RecipientsGroupSelector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecipientsGroupSelectorWrapper);

export { RecipientsGroupSelector };
