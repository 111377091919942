import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { getContactItemById } from 'shared/store/selectors/contact.selector';
import { Button, Divider } from 'src/components/design-system';
import { TeamManager } from 'src/components/team/team-manager.component';
import styles from './recipients-group-selector.module.scss';
import { SearchBox } from 'src/components/design-system/forms/search-box.component';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useMemo, useState } from 'react';
import { formatFullName, normalizedContains } from 'shared/utils';
import { ROUTES } from 'shared/constants';
import { useHistory } from 'react-router';

export function RecipientsPreviewModal({ userIds, open, closeModal }) {
  const lang = useSelector(getLang('COMMUNICATION_CENTER'));
  const getContactById = useSelector(
    ({ contacts, usersManagement }) =>
      id =>
        getContactItemById({ contacts, usersManagement }, id),
  );

  const [searchTerm, setSearchTerm] = useState('');

  const allUsers = useMemo(() => userIds.map(id => getContactById(id)), [userIds, getContactById]);

  const filteredUsers = useMemo(() => {
    // First filter users based on search term
    let result = [...allUsers];

    if (searchTerm.trim()) {
      result = allUsers.filter(user =>
        normalizedContains(formatFullName(user, { withEmail: true }), searchTerm),
      );
    }

    // Then sort alphabetically by full name
    return result.sort((a, b) => {
      const nameA = formatFullName(a, { withEmail: true });
      const nameB = formatFullName(b, { withEmail: true });

      return nameA.localeCompare(nameB);
    });
  }, [allUsers, searchTerm]);

  const filterUsers = e => {
    setSearchTerm(e.target.value);
  };

  const history = useHistory();

  const onRecipientClicked = id => {
    closeModal();
    const url = ROUTES.CONTACTS_PREVIEW.replace(':id', id);
    history.push(url);
  };

  return (
    <Modal
      width={1000}
      centered
      closeIcon={null}
      bodyStyle={{ maxHeight: '65vh', overflowY: 'auto' }}
      title={
        <>
          <div className={styles.modalHeader}>
            <h2 className={styles.modalTitle}>
              {lang.PREVIEW_RECIPIENTS_TITLE.replace('{count}', userIds.length)}
            </h2>
            <SearchBox
              className={styles.modalSearch}
              size="large"
              allowClear
              placeholder={lang.FILTER_PLACEHOLDER}
              onChange={filterUsers}
              value={searchTerm}
            />
          </div>
          <Divider />
        </>
      }
      footer={
        <Button type="primary" onClick={closeModal}>
          {lang.DONE}
        </Button>
      }
      onCancel={closeModal}
      open={open}
    >
      <TeamManager existingUsers={filteredUsers} onClickRow={onRecipientClicked} />
    </Modal>
  );
}
