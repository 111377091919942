import { colorPalette } from 'shared/constants';
import styles from './avatar.module.scss';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

/**
 *
 * @param {object} avatarProps
 * @param {string | undefined} avatarProps.img - The image source
 * @param {string} avatarProps.alt - The alt text for the image or the name to display
 * @param {'small' | 'medium' | 'large'} avatarProps.size - The size of the avatar
 * @param {string} avatarProps.bgColor - The background color of the avatar
 * @param {string} avatarProps.color - The text color of the name
 * @param {object} props - The rest of the props for the img or span element
 * @returns
 */
const Avatar = ({
  img,
  alt = '',
  size = 'medium',
  bgColor = colorPalette.neutral7,
  color = colorPalette.neutral1,
  withHover = false,
  hoverBgColor = undefined,
  hoverShadowColor = undefined,
  ...props
}) => {
  if (!img) {
    const hoverStyles = withHover
      ? {
          '--hover-bg-color': hoverBgColor,
          '--hover-shadow-color': hoverShadowColor,
        }
      : {};
    return (
      <div
        className={classnames([styles.avatar, styles[size]], { [styles.withHover]: withHover })}
        style={{ background: bgColor, ...hoverStyles }}
      >
        <span className={styles.avatarName} {...props} style={{ color: color }}>
          {!!alt.length ? alt : <FontAwesomeIcon icon={faUser} />}
        </span>
      </div>
    );
  }
  return (
    <div className={classnames(styles.avatar, styles.avatarImg)} style={{ background: bgColor }}>
      <img className={styles.image} src={img} alt={alt} {...props} />;
    </div>
  );
};

export { Avatar };
