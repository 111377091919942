import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { formatFullName, sortByAlphabet } from 'shared/utils';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import styles from './recipients-group-selector.module.scss';
import { Button, Select } from 'src/components/design-system';
import { Tooltip } from 'src/components/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

export function IndividualsSelector({ value, onChange, name, ...props }) {
  const lang = useSelector(getLang('COMMUNICATION_CENTER'));
  const users = useSelector(({ usersManagement }) => usersManagement.list);
  const [showSelector, setShowSelector] = useState(false);

  const usersList = useMemo(() => {
    if (_.isNull(users)) return [];
    return users
      .filter(({ isDeactivated = false }) => !isDeactivated)
      .map(({ id, email, firstName, lastName }) => ({
        value: id,
        label: formatFullName({ firstName, lastName, email }, { withEmail: true }),
      }))
      .sort((a, b) => sortByAlphabet(a?.label, b?.label));
  }, [users]);

  return (
    <div className={styles.root}>
      {!showSelector && (
        <Button className={styles.addIndividuals} type="link" onClick={() => setShowSelector(true)}>
          {lang.ADD_INDIVIDUAL}
        </Button>
      )}

      {showSelector && (
        <div className={styles.individualsWrapper}>
          <label className={styles.selectorLabel}>{lang.INDIVIDUALS}</label>

          <div className={styles.selectorWrapper}>
            <Select
              mode="multiple"
              name={name}
              placeholder={lang.INDIVIDUALS_PLACEHOLDER}
              value={value}
              className={styles.selector}
              onChange={onChange}
              bordered={false}
              options={usersList}
              suffixIcon={null}
              allowClear={false}
              popupClassName={styles.dropdownOptions}
              size="large"
              noMarginBottom
              popupMatchSelectWidth
              tagStyle={{ margin: 0, padding: '0.25rem 0.5rem' }}
              {...props}
            />

            <Tooltip title={lang.REMOVE_GROUP} mouseEnterDelay={0.3}>
              <Button
                className={styles.removeButton}
                onClick={() => {
                  onChange(name, []);
                  setShowSelector(false);
                }}
                type="link"
                danger
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
}
