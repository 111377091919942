import { DEFAULT_LOCALE } from '../../utils/date.utils';

export const findDefaultScheduleUrl = (schedules = []) =>
  schedules.find(schedule => !schedule.roles?.length && !schedule.professions?.length)?.url;

export const findScheduleByProfessionAndRoles = (schedules = [], user = {}) => {
  const { profession, professional = {} } = user;
  const { role } = professional || {};
  return schedules.find(
    schedule =>
      schedule.roles?.find(r => r === role) || schedule.professions?.find(p => p === profession),
  )?.url;
};

export const getMobileWidgets = ({ organization }) =>
  organization?.configuration?.dashboardWidgets?.mobile;

export const getOrganizationLocale = ({ organization }) => organization.locale;

export const isWorkingHoursNow = ({
  organization = {
    workingHoursStartTime: 7,
    workingHoursEndTime: 19,
    locale: DEFAULT_LOCALE,
    timezone: 'America/New_York',
  },
}) => {
  const now = new Date();

  // Get the hour in the organization's timezone
  const nowHours = Number(
    new Intl.DateTimeFormat(organization.locale, {
      hour: 'numeric',
      hourCycle: 'h23', // Ensures 24-hour format
      timeZone: organization.timezone, // Uses org's timezone
    }).format(now),
  );

  return (
    nowHours >= organization.workingHoursStartTime && nowHours <= organization.workingHoursEndTime
  );
};
