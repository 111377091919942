import { AUTHENTICATION_SERVICE_URL, getBaseURL } from './APIconstants';

export const USER_PERMISSIONS = {
  ROOT: 'ROOT',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  COLLABORATOR: 'COLLABORATOR',
  VIEWER: 'VIEWER',
};

export const ROOT_PERMISSIONS_GROUP = [USER_PERMISSIONS.ROOT];
export const ACCOUNT_ADMIN_PERMISSIONS_GROUP = [
  USER_PERMISSIONS.ACCOUNT_ADMIN,
  ...ROOT_PERMISSIONS_GROUP,
];
export const COLLABORATOR_PERMISSIONS_GROUP = [
  USER_PERMISSIONS.COLLABORATOR,
  ...ACCOUNT_ADMIN_PERMISSIONS_GROUP,
];
export const VIEWER_PERMISSIONS_GROUP = [
  USER_PERMISSIONS.VIEWER,
  ...COLLABORATOR_PERMISSIONS_GROUP,
];

export const DEFAULT_USER_PERMISSION = USER_PERMISSIONS.VIEWER;

export const ALLOWED_PERMISSIONS_FOR_CHANGE = [
  USER_PERMISSIONS.VIEWER,
  USER_PERMISSIONS.COLLABORATOR,
  USER_PERMISSIONS.ACCOUNT_ADMIN,
];

export const REFRESH_API_KEY_INTERVAL = 1000 * 60 * 60 * 12; // 12 hours

export const WIDGET_REDIRECT_QUERY_PARAM = 'widgetRedirect=1';

export const OTP_ERROR_MESSAGES = {
  CODE_DOES_NOT_MATCH: "code doesn't match",
  VERIFICATION_ATTEMPTS_LIMIT_EXCEED: 'verification attempts limit exceeded',
};

export const AUTH_USER_TYPE_NORMAL = 'NORMAL';
export const AUTH_USER_TYPE_MAGIC_LINK = 'MAGIC_LINK';

export const OAUTH_PROVIDERS = {
  GOOGLE: 'google',
  SOAP: 'soap',
};

export const OAUTH_CONFIGURATIONS_GENERAL = {
  GOOGLE: {
    MOBILE_OAUTH_URL: 'https://accounts.google.com/',
    MOBILE_SCOPE: ['openid', 'profile', 'email'],
    WEB_OAUTH_URL: 'https://accounts.google.com/o/oauth2/v2/auth',
    WEB_SCOPE: 'openid profile email',
    RESPONSE_TYPE: 'code',
    ACCESS_TYPE: 'offline',
    PROMPT: 'consent',
    PROVIDER: OAUTH_PROVIDERS.GOOGLE,
  },
  SOAP: {
    WEB_OAUTH_URL: 'https://soap.memberclicks.net/oauth/v1/authorize',
    OAUTH_URL: 'https://soap.memberclicks.net/oauth/v1/authorize',
    CLIENT_ID: '3WGQMByiFMVgn2hFpemO',
    RESPONSE_TYPE: 'code',
    SCOPE: 'read',
    PROVIDER: OAUTH_PROVIDERS.SOAP,
  },
};

export const AUTHENTICATION_TYPE = Object.freeze({
  OTP: 'OTP',
  SAML: 'SAML',
  OAUTH: 'OAuth',
});

export const getSamlUrl = ({ authenticationMethodId, organizationId }) => {
  const baseUrl = getBaseURL();
  return `${baseUrl}${AUTHENTICATION_SERVICE_URL}saml/login?authenticationMethodId=${authenticationMethodId}&organizationId=${organizationId}`;
};
