import React from 'react';
import { PandaNotFound } from '../../../images';
import styles from './NoContent.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import classNames from 'classnames';

const NoContent = ({ text, className, image = PandaNotFound, withMixBlendMode = true }) => {
  const lang = useSelector(getLang('GENERAL'));
  return (
    <div className={classNames(className, styles.root)}>
      <img
        src={image}
        className={classNames(styles.panda, { [styles.withMixBlendMode]: withMixBlendMode })}
        alt={text || lang.NO_ITEMS_FOUND}
      />
      <h4>{text || lang.NO_ITEMS_FOUND}</h4>
    </div>
  );
};

NoContent.defaultProps = {
  text: '',
  className: null,
};

export { NoContent };
