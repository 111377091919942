import _ from 'lodash';

export const getSelectedWorkspace = ({ workspace }) =>
  workspace?.list?.find(w => w.id === workspace.selected);

export const getOperationalData = ({ workspace, organization }) => {
  // workspace config takes precedence over organization config
  const workspaceConfig = getSelectedWorkspace({ workspace })?.operational;

  const calendarUrl = workspaceConfig?.calendarUrl || organization?.calendarUrl;
  const scheduleUrls = workspaceConfig?.scheduleUrls.length
    ? workspaceConfig.scheduleUrls
    : organization?.scheduleUrls;
  const quickLinks = workspaceConfig?.quickLinks.length
    ? workspaceConfig.quickLinks
    : organization?.configuration?.quickLinks;

  const dashboardBanner = _.isEmpty(workspaceConfig?.dashboardBanner)
    ? organization?.configuration?.dashboardBanner
    : workspaceConfig?.dashboardBanner;

  return {
    calendarUrl,
    scheduleUrls,
    quickLinks,
    dashboardBanner,
  };
};

export const getSelectedWorkspaceId = ({ workspace }) => {
  return workspace?.selected ?? getWorkspaces({ workspace })?.[0]?.id;
};

export const getWorkspaces = ({ workspace }) => {
  return workspace.list;
};

export const getWorkspaceById =
  id =>
  ({ workspace }) => {
    return id ? workspace?.list?.find(w => w.id === id) : null;
  };

export const isThereOnlyOneWorkspace = ({ workspace }) => {
  return workspace?.list?.length === 1;
};

export const hasOnePublicWorkspace = ({ workspace, selectedId }) => {
  return workspace?.list?.filter(({ id }) => id !== selectedId).some(w => !w.restricted);
};

export const userIsWorkspaceManager = ({ workspace, auth }) =>
  getSelectedWorkspace({ workspace })?.managers?.includes(auth?.user?.id);

export const isAWorkspaceManager = ({ auth, workspace }) =>
  workspace?.list?.some(w => w.managers?.includes(auth?.user?.id));
