import React, { useCallback, useState } from 'react';
import { NotFound } from 'src/components/common';
import { useHistory } from 'react-router';
import { ResourceItemComponent } from './resource-item.component';

const ResourceList = ({
  resources = [],
  canEdit = false,
  doRemove = () => {},
  analyticsTriggers = {},
  notFoundText = '',
  wrapperClass = '',
  showNotFoundPanda = false,
  openInNewTab = false,
}) => {
  const [loading, setLoading] = useState(null);

  const handleRemove = useCallback(
    async (e, id) => {
      if (loading) return;
      e.stopPropagation();
      e.preventDefault();

      setLoading(id);

      await doRemove(id);
      const itemToRemove = resources.find(item => item.id === id);
      analyticsTriggers?.remove?.(itemToRemove);

      setLoading(null);
    },
    [analyticsTriggers, doRemove, loading, resources],
  );

  const history = useHistory();

  const onClick = useCallback(
    (navigateTo, item) => {
      analyticsTriggers?.open?.(item);
      if (openInNewTab) {
        window.open(navigateTo, '_blank').focus();
        return;
      }
      history.push(navigateTo);
    },
    [history, analyticsTriggers, openInNewTab],
  );

  const onOpenExternalUrl = useCallback(
    (navigateTo, item) => {
      analyticsTriggers?.open?.(item);
    },
    [analyticsTriggers],
  );

  if (!resources.length) {
    return showNotFoundPanda ? <NotFound text={notFoundText} /> : <p>{notFoundText}</p>;
  }

  return resources.map((item, index) => (
    <ResourceItemComponent
      key={index}
      parsedResource={item}
      canEdit={canEdit}
      loading={loading}
      handleRemove={handleRemove}
      onOpenExternalUrl={onOpenExternalUrl}
      onClick={onClick}
      wrapperClass={wrapperClass}
    />
  ));
};

export { ResourceList };
