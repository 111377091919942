import React, { useCallback, useMemo } from 'react';
import { Select } from 'src/components/design-system/forms';
import { formatFullName, sortByAlphabet } from 'shared/utils';
import { useSelector } from 'react-redux';
import { getContributors } from 'shared/store/selectors/contact.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import { message } from 'antd';
import _ from 'lodash';

export function ContributorsSelector({ onChange, ...props }) {
  const usersAndContributors = useSelector(getContributors);
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));

  const selectedContributors = useMemo(() => {
    return (
      props.value.map(contributor => {
        if (!contributor) return null;
        const { id, contributorId, userId, displayName } = contributor;
        return {
          value: JSON.stringify({ id, contributorId, userId, displayName }),
          label: displayName,
        };
      }) || []
    );
  }, [props.value]);

  const options = useMemo(() => {
    // Create a Set of unique identifiers for selected contributors
    const selectedSet = new Set(
      selectedContributors.map(selected => {
        const parsed = JSON.parse(selected.value);
        return `${parsed.id || parsed.userId || parsed.contributorId}`;
      }),
    );

    const allOptions = usersAndContributors
      // Filter out the selected contributors from all options
      .filter(({ id, userId, contributorId }) => {
        const key = `${id || userId || contributorId}`;
        return !selectedSet.has(key);
      })
      .map(({ id, contributorId, firstName, lastName, email }) => {
        return {
          value: JSON.stringify({ id, contributorId }),
          label: formatFullName({ firstName, lastName, email }, { withEmail: true }),
        };
      })
      .sort((a, b) => sortByAlphabet(a?.label, b?.label));

    // Combine the selected contributors with the filtered options, put the selected contributors first
    return [...selectedContributors, ...allOptions];
  }, [usersAndContributors, selectedContributors]);

  const bindSelectedContributor = useCallback(
    selected => {
      try {
        const { id, contributorId, userId, displayName } = JSON.parse(selected);

        if (!id && !userId && !contributorId && displayName) {
          return { displayName }; // for existing external contributors before saving
        }

        // userId for existing internal contributors (users), id for new internal contributors
        const isUser = !_.isUndefined(id) || !_.isUndefined(userId);

        const selectedUser = isUser
          ? usersAndContributors.find(user => user.id === id || user.id === userId)
          : usersAndContributors.find(user => user.contributorId === contributorId);

        if (!selectedUser) {
          // not supposed to happen
          return null;
        }

        if (
          !_.isUndefined(id) &&
          _.isUndefined(userId) &&
          selectedContributors.some(c => {
            const contributor = JSON.parse(c.value);
            return contributor.userId === id;
          })
        ) {
          return null;
        }

        return isUser
          ? {
              // for existing internal contributors (users)
              userId: selectedUser.id,
              displayName: formatFullName(selectedUser),
            }
          : {
              // for existing external contributors after saving
              contributorId: contributorId,
              displayName: selectedUser.displayName,
            };
      } catch (e) {
        if (selectedContributors.find(({ label }) => label === selected)) {
          return null;
        }
        message.info(`${lang.EXTERNAL_TOOLTIP_CONTRIBUTOR.replace('{name}', selected)}`);
        return { displayName: selected }; // for new external contributors
      }
    },
    [usersAndContributors, selectedContributors, lang.EXTERNAL_TOOLTIP_CONTRIBUTOR],
  );

  const onChangeInternal = useCallback(
    (name, currentValues) => {
      const updatedValues = currentValues.map(bindSelectedContributor).filter(value => value);
      onChange(updatedValues);
    },
    [bindSelectedContributor, onChange],
  );

  return (
    <Select
      mode="tags"
      name="contributors"
      onChange={onChangeInternal}
      options={options}
      bordered={false}
      {...props}
      value={selectedContributors}
    />
  );
}
