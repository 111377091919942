export const NEWS_FEED_DASHBOARD_PARAMS = {
  page: 1,
  pageSize: 100,
};

export const REQUEST_ACCESS_ENTITIES = {
  KNOWLEDGE_ITEM: 'knowledge-item',
  KNOWLEDGE_CATEGORY: 'knowledge-category',
  LAB_CATEGORY: 'lab-category',
  CHECKLIST: 'checklist',
};
