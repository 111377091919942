import { currentEnvironment } from '../services/Environment';
import { OAUTH_CONFIGURATIONS_GENERAL } from '.';

export const REMOTE_CONFIG_KEYS = {
  APPLICATION_VERSIONS: 'APPLICATION_VERSIONS',
  LANGUAGE: 'LANGUAGE',
  MAINTENANCE: 'MAINTENANCE',
  GENERAL_CONFIG: 'GENERAL_CONFIG',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  OAUTH_CONFIGURATIONS: 'OAUTH_CONFIGURATIONS',
};

export const FEATURE_FLAGS = {
  KNOWLEDGE_STATISTICS: 'KNOWLEDGE_STATISTICS',
  NEW_AUTHENTICATION_WEB: 'NEW_AUTHENTICATION_WEB',
  NEW_AUTHENTICATION_MOBILE: 'NEW_AUTHENTICATION_MOBILE',
};

export const REMOTE_CONFIG_DEFAULT = {
  [REMOTE_CONFIG_KEYS.APPLICATION_VERSIONS]: {},
  [REMOTE_CONFIG_KEYS.LANGUAGE]: {}, // TODO: update to default props
  [REMOTE_CONFIG_KEYS.MAINTENANCE]: { ACTIVE: false, ESTIMATED_END: '' },
  [REMOTE_CONFIG_KEYS.GENERAL_CONFIG]: {
    FEEDBACK_FORM: '',
    ALLOW_IFRAME_DOMAINS: ['headtotoe.io', 'c8.health', 'c8health.com', 'mdcalc.com'],
    USER_PROFILE_MANDATORY_DATE: '2023-04-24',
    HOURS_BEFORE_TOKEN_EXPIRATION_TO_REFRESH: 3,
    IMPROVED_SEARCH_CONFIG: {
      KNOWLEDGE: {
        keys: ['title', 'subtitle', 'keywords'],
        includeScore: true,
        threshold: 0.3,
      },
      LAB: {
        keys: ['title', 'value', 'keywords'],
        includeScore: true,
        ignoreLocation: true,
        threshold: 0.3,
      },
      CATEGORY: {
        keys: ['title', 'keywords'],
        includeScore: true,
        ignoreLocation: true,
        threshold: 0.3,
      },
      CONTACTS: {
        keys: ['firstName', 'lastName', 'email', 'department'],
        includeScore: true,
        ignoreLocation: true,
        threshold: 0.3,
      },
    },
    notifications: {
      checkIntervalDays: 10,
      promotionCheckIntervalDays: 5,
    },
  },
  [REMOTE_CONFIG_KEYS.FEATURE_FLAGS]: {
    [FEATURE_FLAGS.KNOWLEDGE_STATISTICS]: true,
    [FEATURE_FLAGS.NEW_AUTHENTICATION_WEB]: true,
    [FEATURE_FLAGS.NEW_AUTHENTICATION_MOBILE]: true,
  },
  [REMOTE_CONFIG_KEYS.OAUTH_CONFIGURATIONS]: {
    GOOGLE: {
      CLIENT_ID: '117618283161-9iapajj8v0aj84fm3hdqpp6plkbbn59d.apps.googleusercontent.com',
      REDIRECT_URI_WEB: 'http://localhost:3000/auth/callback/google',
      REDIRECT_URI: 'https://staging.app.c8health.com/auth/callback/google',
      ...OAUTH_CONFIGURATIONS_GENERAL.GOOGLE,
    },
    SOAP: {
      REDIRECT_URI_WEB: 'http://localhost:3000/auth/callback/soap',
      REDIRECT_URI: 'https://staging.app.c8health.com/auth/callback/soap',
      ...OAUTH_CONFIGURATIONS_GENERAL.SOAP,
    },
  },
};

const USE_LOCAL_CONFIG_BY_ENV = {
  development: true,
  staging: false,
  production: false,
};

export const USE_LOCAL_CONFIG = USE_LOCAL_CONFIG_BY_ENV[currentEnvironment()];
export const MINIMUM_FETCH_INTERVAL_MS = 30000;
