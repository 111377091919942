import { connect } from 'react-redux';
import { NewsFeedItemComponent } from './news-feed-item.component';
import { getContactItemById } from 'shared/store/selectors/contact.selector';

const mapStateToProps = ({ application, lang, contacts, usersManagement }, { sentBy }) => ({
  adminMode: application.adminMode,
  lang: lang.NEWS_FEED,
  user: getContactItemById({ contacts, usersManagement }, sentBy),
});

export const NewsFeedItem = connect(mapStateToProps)(NewsFeedItemComponent);
