import { useSelector } from 'react-redux';
import styles from './recipients-group-selector.module.scss';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button, Select } from 'src/components/design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faTrash, faXmark, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useProfileTagsOption } from 'shared/hooks/profile.hooks';
import { Tag } from 'antd';
import { USER_TAGS_KEYS } from 'shared/constants';
import { Tooltip } from 'src/components/core';
import userServiceApi from 'shared/api/user.service.api';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useTotalRecipients } from './total-recipients.context';
import { RecipientsPreviewModal } from './recipients-preview-modal';
import { LearnMore } from 'src/components/common/learn-more';

export function RecipientsGroupSelectorComponent({
  groups,
  onChange,
  name,
  users,
  fetchUsers,
  usersStats,
  fetchUserStats,
}) {
  const lang = useSelector(getLang('COMMUNICATION_CENTER'));
  const langRecipients = useSelector(getLang('RECIPIENTS_SELECTOR'));
  const [innerGroups, setInnerGroups] = useState(
    groups.map(group => ({ id: nanoid(), values: group, recipients: [], loading: false })),
  );

  // Fetch users on mount if not fetched yet
  useEffect(() => {
    if (_.isNull(users)) fetchUsers();
  }, [users, fetchUsers]);

  // Fetch users stats on mount if not fetched yet
  useEffect(() => {
    if (_.isNull(usersStats)) fetchUserStats();
  }, [usersStats, fetchUserStats]);

  const {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOfTrainingOptions,
    locationOptions,
    permissionsOptions,
    specialtyOptions,
    divisionOptions,
  } = useProfileTagsOption({ stats: usersStats, showStats: false });

  const options = [
    {
      label: langRecipients.DEPARTMENT,
      options: departmentOptions,
    },
    {
      label: langRecipients.ROTATION,
      options: rotationOptions,
    },
    {
      label: langRecipients.SPECIALTY,
      options: specialtyOptions,
    },
    {
      label: langRecipients.DIVISION,
      options: divisionOptions,
    },
    {
      label: langRecipients.ROLE,
      options: roleOptions,
    },
    {
      label: langRecipients.PROFESSION,
      options: professionOptions,
    },
    {
      label: langRecipients.LEVEL_OF_TRAINING,
      options: levelOfTrainingOptions,
    },
    {
      label: langRecipients.LOCATION,
      options: locationOptions,
    },
    {
      label: langRecipients.PERMISSIONS,
      options: permissionsOptions,
    },
  ];

  const { recipients, loading: loadingTotal } = useTotalRecipients();

  const getTotalRecipientsPerGroup = async (newGroupValues, groupId) => {
    const groupIndex = innerGroups.findIndex(group => group.id === groupId);
    if (groupIndex !== -1) {
      setInnerGroups(prev => {
        const temp = [...prev];
        temp[groupIndex].loading = true;
        return temp;
      });
    }

    const { data, error } = await userServiceApi.getUserGroups({ groups: [newGroupValues] });

    if (groupIndex !== -1) {
      setInnerGroups(prev => {
        const temp = [...prev];
        temp[groupIndex].loading = false;
        return temp;
      });
    }

    if (error) {
      return 0;
    }

    return data.users;
  };

  const onAddGroup = () => {
    setInnerGroups(prev => [...prev, { id: nanoid(), values: {}, recipients: [], loading: false }]);
  };

  const onGroupChange = async (groupId, tagOptions) => {
    // Do not allow more then one empty group
    if (tagOptions.length === 0 && innerGroups.length > 1) {
      onRemoveGroup(groupId);
      return;
    }

    const groupIndex = innerGroups.findIndex(group => group.id === groupId);

    // Create new group values
    const newGroupValues = tagOptions.reduce((acc, tag) => {
      const [key, value] = tag.split('.');
      return {
        ...acc,
        [key]: value,
      };
    }, {});

    // Get total recipients for the updated group
    const newTotalPerGroup = await getTotalRecipientsPerGroup(newGroupValues, groupId);

    // Update the group values and recipients
    setInnerGroups(prev => {
      const newInnerGroups = [...prev];
      newInnerGroups[groupIndex].values = newGroupValues;
      newInnerGroups[groupIndex].recipients = newTotalPerGroup;
      return newInnerGroups;
    });

    // Update the total recipients
    const newGroups = [...groups];
    newGroups[groupIndex] = newGroupValues;

    // Update the form values
    onChange(name, newGroups);
  };

  const onRemoveGroup = async groupId => {
    const newGroups = innerGroups.filter(group => group.id !== groupId).map(group => group.values);

    // Update the form values
    onChange(name, newGroups);

    // Remove the group from the inner groups
    setInnerGroups(prev => prev.filter(group => group.id !== groupId));
  };

  const [showRecipientsModal, setShowRecipientsModal] = useState(false);
  const [recipientsForModal, setRecipientsForModal] = useState([]);

  const openRecipientModel = recipientsToShow => {
    setRecipientsForModal(recipientsToShow);
    setShowRecipientsModal(true);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.recipientsLabelWrapper}>
          <label className={styles.selectorLabel}>
            {lang.RECIPIENTS_DESCRIPTION}{' '}
            <LearnMore
              text={lang.LEARN_MORE_RECIPIENTS_GROUPS}
              link="https://help.c8health.com/sending-a-message-to-users"
              size="sm"
            />
          </label>
          {recipients.length > 0 && (
            <button
              type="button"
              className={styles.totalRecipients}
              disabled={recipients.length === 0}
              onClick={() => openRecipientModel(recipients)}
            >
              {loadingTotal ? (
                <FontAwesomeIcon icon={faSpinnerThird} spin={loadingTotal} />
              ) : (
                recipients.length
              )}{' '}
              {lang.RECIPIENTS_DESCRIPTION.toLowerCase()}
            </button>
          )}
        </div>

        <div className={styles.selectors}>
          {innerGroups.map(group => (
            <div key={group.id} className={styles.selectorWrapper}>
              <Select
                mode="multiple"
                name={group.id}
                className={styles.selector}
                onChange={onGroupChange}
                bordered={false}
                options={options}
                allowClear={false}
                placeholder={lang.RECIPIENTS_PLACEHOLDER}
                popupClassName={styles.dropdownOptions}
                size="large"
                value={Object.entries(group.values).map(([key, value]) => `${key}.${value}`)}
                noMarginBottom
                popupMatchSelectWidth
                tagRender={({ label, value, closable, onClose }) => {
                  const [key, tagValue] = value.split('.');
                  const [keyTag] = Object.entries(USER_TAGS_KEYS).find(([_, v]) => v === key);
                  const keyLang = langRecipients[keyTag];

                  const selectedTags = Object.values(
                    innerGroups.find(g => g.id === group.id).values,
                  );

                  // there is no index in the renderTag props, so we need to find the last tag by the selectedTags
                  const isLast = selectedTags[selectedTags.length - 1] === tagValue;
                  const showConnector = selectedTags.length > 1 && !isLast;

                  return (
                    <div className={styles.tagOptionWrapper}>
                      <Tag
                        className={styles.tagOption}
                        closable={closable}
                        onClose={onClose}
                        closeIcon={
                          <FontAwesomeIcon
                            icon={faXmark}
                            size="lg"
                            className={styles.closeTagIcon}
                          />
                        }
                      >
                        <label className={styles.tagLabel}>{keyLang}</label>
                        {label}
                      </Tag>
                      {showConnector && (
                        <label className={styles.labelConnector}> {lang.HOW_ARE} </label>
                      )}
                    </div>
                  );
                }}
              />

              {Object.values(group.values).length > 0 && (
                <button
                  type="button"
                  className={classNames(styles.marginRight, styles.totalRecipients)}
                  onClick={() => openRecipientModel(group.recipients)}
                  disabled={group.recipients.length === 0}
                >
                  {group.loading ? (
                    <FontAwesomeIcon icon={faSpinnerThird} spin={group.loading} />
                  ) : (
                    group.recipients.length
                  )}{' '}
                  {lang.RECIPIENTS_DESCRIPTION.toLowerCase()}
                </button>
              )}

              {innerGroups.length > 1 && (
                <Tooltip title={lang.REMOVE_GROUP} mouseEnterDelay={0.3}>
                  <Button
                    className={styles.removeButton}
                    onClick={async () => await onRemoveGroup(group.id)}
                    type="link"
                    danger
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Tooltip>
              )}
            </div>
          ))}
        </div>

        <Button
          className={styles.addGroup}
          icon={<FontAwesomeIcon icon={faPlus} />}
          disabled={innerGroups.some(group => Object.keys(group.values).length === 0)}
          onClick={onAddGroup}
        >
          {lang.ADD_RECIPIENTS_GROUP}
        </Button>
      </div>
      <RecipientsPreviewModal
        userIds={recipientsForModal.map(({ id }) => id)}
        open={showRecipientsModal}
        closeModal={() => setShowRecipientsModal(false)}
      />
    </>
  );
}
